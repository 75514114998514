<!-- eslint-disable no-restricted-syntax -->
<template>
  <div>
    <div
      id="top-fltr-container"
      class="bdc_top"
      :class="isCampaignTableExpanded ? 'extHeader' : 'CollHeader'"
      :style="`${isHidden ? 'display: none' : ''}`"
    >
      <div
        v-if="!hideFilterBar && selectedSubMenuOption !== 'pixelPage'"
        id="sub_filter_bar"
        class="sub_filters_bar sub_filters_wrap"
        :class="[isCampaignTableExpanded ? 'zeroTop' : '', isMniAccount ? 'mniAccountStyle' : '']"
      >
        <div
          class="filter-and-date-tabs"
          :class="{
            'move-filter-tab': !isLeftNavCollapsed,
            'cstm-mni-filter': isMniAccount,
          }"
        >
          <div id="top_sub_filters_left" class="sub_filters_left">
            <div v-if="!isMniAccount" class="search-checkbox-dropdown">
              <dropdown-checkbox-search
                id="campaign-filter-dropdown"
                key="campaign"
                name="campaign"
                label="Search..."
                :is-top-filter="true"
                :options="filters.campaignOptions"
                :fancy="true"
                :multiple="true"
                :loading="isAdGroupLoading || filtersAppliedLoading"
                label-key="value"
                title=""
                :inside-label="true"
                :header="labels.campaigns"
                component-id="campaignOptionDropdown"
                :show-advanced-filters="showAdvancedFilters"
                :show-all="false"
                :value="selectedFilters.campaignOptions ? selectedFilters.campaignOptions : []"
                original-component="dropdown"
                :class-name="
                  `campaign-select ${
                    isAdGroupLoading || filtersAppliedLoading || filtersApplied ? 'disabled' : ''
                  }`
                "
                :disabled="activeSolutionId === 7"
                @dropdown-closed="onCloseCampaignDropdown(true)"
                @input="
                  (val) => {
                    setSelectedFilters('campaignOptions', val);
                  }
                "
                @selected-options="
                  (val) => {
                    setSelectedFilters('campaignOptions', val, true);
                  }
                "
              />
            </div>
            <div
              v-if="!isMniAccount"
              :class="
                `search-checkbox-dropdown ${
                  isAdGroupLoading || filtersAppliedLoading || filtersApplied ? 'disabled' : ''
                }`
              "
            >
              <dropdown-checkbox-search
                id="adGroup-filter-dropdown"
                key="adGroup"
                name="adGroup"
                label="Search..."
                :is-top-filter="true"
                :options="filters.adGroupOptions || adGroupOptions"
                :fancy="true"
                :multiple="true"
                :loading="isAdGroupLoading || filtersAppliedLoading || filtersApplied"
                label-key="value"
                title=""
                :inside-label="true"
                :header="labels.adGroup"
                component-id="adGroupOptionDropdown"
                :show-advanced-filters="showAdvancedFilters"
                :show-all="false"
                :value="selectedFilters.adGroupOptions ? selectedFilters.adGroupOptions : []"
                original-component="dropdown"
                :class-name="
                  `ad-group-select ${
                    isAdGroupLoading || filtersAppliedLoading || filtersApplied ? 'disabled' : ''
                  }`
                "
                :disabled="activeSolutionId === 7"
                @dropdown-closed="onCloseAdGroupDropdownVia"
                @input="
                  (val) => {
                    setSelectedFilters('adGroupOptions', val);
                  }
                "
                @selected-options="
                  (val) => {
                    setSelectedFilters('adGroupOptions', val, true);
                  }
                "
              />
            </div>
            <div
              v-if="!isMniAccount"
              :style="`${[7, 3].includes(activeSolutionId) ? 'display: none' : ''}`"
              :class="
                `search-checkbox-dropdown ${
                  isAdGroupLoading ||
                  filtersAppliedLoading ||
                  filtersApplied ||
                  isConversionDisabled
                    ? 'disabled'
                    : ''
                }`
              "
            >
              <dropdown-checkbox-search
                v-if="!disablePixelFilter && !isMniAccount"
                id="conversions-filter-dropdown"
                key="conversions"
                name="conversions"
                label="Search..."
                :is-top-filter="true"
                :options="filters.pixelOptions || pixelOptions"
                :fancy="true"
                :multiple="true"
                :loading="isAdGroupLoading || filtersAppliedLoading || filtersApplied"
                label-key="value"
                title=""
                :inside-label="true"
                :header="labels.conversions"
                component-id="conversionOptionDropdown"
                :show-advanced-filters="showAdvancedFilters"
                :show-all="false"
                :value="selectedFilters.pixelOptions ? selectedFilters.pixelOptions : []"
                original-component="dropdown"
                :class-name="
                  `pixel-select ${
                    isAdGroupLoading || filtersAppliedLoading || filtersApplied ? 'disabled' : ''
                  }`
                "
                @dropdown-closed="onCloseConversionsDropdown"
                @input="
                  (val) => {
                    setSelectedFilters('pixelOptions', val);
                  }
                "
                @selected-options="
                  (val) => {
                    setSelectedFilters('pixelOptions', val, true);
                  }
                "
              />
            </div>
            <div v-if="isMniAccount && !isMniReportDashboard" style="margin-left:-33px">
              <b-radio-select-checkbox
                id="Events-filter-dropdown-multi"
                icon-pos="left"
                :options="eventOptions"
                title=""
                header="Events"
                class="events-dd"
                :disabled="
                  selectedDashboardTab === 'journeyanalytics' ||
                    mniTemplateId === 17 ||
                    title === 'Media Now Interactive // Custom Reports'
                "
                :disable-drop-down-select-option="true"
                :event-loading-for-mni="eventLoadingForMni"
                :enable-option-click-select="true"
                :apply-white-labeling="true"
                @selected-options="onSelectEventsMni"
                @dropdown-closed="onEventDropdownClose"
              />
            </div>
            <div v-if="isMniReportDashboard" style="margin-left:-33px">
              <b-radio-select-checkbox-for-event
                id="Events-filter-dropdown"
                icon-pos="left"
                :options="eventOptions"
                title=""
                header="Events"
                class="events-dd"
                :disabled="
                  selectedDashboardTab === 'journeyanalytics' ||
                    mniTemplateId === 17 ||
                    title === 'Media Now Interactive // Custom Reports'
                "
                :disable-drop-down-select-option="true"
                :event-loading-for-mni="eventLoadingForMni"
                :enable-option-click-select="true"
                :apply-white-labeling="true"
                @dropdown-closed="onSelectEventDropdownClose"
              />
            </div>
            <div v-if="isMniAccount">
              <b-select-checkbox
                id="lookback-filter-dropdown"
                header="Lookback Window (days)"
                icon-pos="left"
                :options="daysToconvertOptions"
                class="events-dd"
                :apply-white-labeling="true"
                :multi-select-enabled="false"
                :disabled="isAdGroupLoading || title === 'Media Now Interactive // Custom Reports'"
                :disable-tool-tip="true"
                @selected-options="onSelectDayToConvert"
              />
            </div>
            <div
              class="ftop-icons fltr-btn tt"
              :style="`${isMniReportDashboard ? 'margin-left: -10px;' : ''}`"
              :class="{
                disabled:
                  disableAdvancedFilter ||
                  title === 'Media Now Interactive // Custom Reports' ||
                  selectedDashboardTab === 'journeyanalytics',
              }"
              @click="handleShowAdvancedFilters(null)"
            >
              <div class="ftop-icon">
                <font-awesome-icon
                  id="adv-filter-icon"
                  :icon="['far', 'filter']"
                  class="fa-regular fa-filter btn-icon"
                />
              </div>
              <div v-if="appliedFilterCount > 0" class="applied-cnt">
                <span class="acnum">{{ appliedFilterCount }}</span>
              </div>
              <div class="ttip-wrap"><div class="ttip">Advanced Filters</div></div>
            </div>
          </div>
          <div
            id="top_sub_filters_right"
            class="sub_filters_right"
            :class="isLeftNavCollapsed ? '' : 'move-rightside-tab'"
          >
            <div
              class="wrap4icon icon-cal ical2"
              :class="{ disabled: disableDateFilter }"
              style="top: 0px; float: right"
            >
              <div class="drp-wrap">
                <div class="compare-label b-datepicker-container">
                  <b-datepicker
                    v-if="isMniReportDashboard"
                    :week1="[dates.startDate, dates.endDate]"
                    :week2="[dates.compareStartDate, dates.compareEndDate]"
                    :campaign-options="ioOptions"
                    :active-solution-id="activeSolutionId"
                    compare-with-text="vs:"
                    :hide-compare-with="hideCompareWith()"
                    :class="isLeftNavCollapsed ? '' : 'move-rightside-datepicker'"
                    @change="bDatepickerChanges"
                  />
                  <b-datepicker
                    v-else
                    :week1="[dates.startDate, dates.endDate]"
                    :week2="[dates.compareStartDate, dates.compareEndDate]"
                    :campaign-options="campaignOptions"
                    :active-solution-id="activeSolutionId"
                    compare-with-text="vs:"
                    :hide-compare-with="hideCompareWith()"
                    :class="isLeftNavCollapsed ? '' : 'move-rightside-datepicker'"
                    @change="bDatepickerChanges"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- bdc_right end -->
    </div>
    <advanced-filters2
      :key="`JSON.stringify(advancedFilters)|${selectedDashboardTab}`"
      :show-advanced-filters="showAdvancedFilters"
      :is-advance-filter-loading="isAdvanceFilterLoading"
      :initial-filters="advancedFilters"
      :active-solution-id="activeSolutionId"
      :filters-to-hide="filtersToHide"
      :on-close-campaign-dropdown="onCloseCampaignDropdown"
      :get-conversion-pixel-options="getConversionPixelOptions"
      :get-media-types="getMediaTypes"
      :get-audiences="getAudiences"
      :get-creatives="getCreatives"
      :get-keywords="getKeywords"
      :is-mni-report-dashboard="isMniReportDashboard"
      :is-advance-filter-in-overview="isAdvanceFilterInOverview && !isMniReportDashboard"
      @on-close="onCloseAdvanceFilters"
      @on-apply="onAdvanceFilterApply"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import _ from 'underscore';
import planApi from 'adready-api/api/plans';
import { formatDateForAPI } from '@/util/apiDateFormat';
import {
  MAX_DAYS_TO_CONVERT,
  MAX_DAYS_TO_CONVERT_JOURNEY,
  DATEPICKER_COMPARE_RANGE_OPTIONS,
  DATEPICKER_RANGE_OPTIONS,
  CAMPAIGN_FILTER,
  ADGROUP_FILTER,
  PIXEL_FILTER,
  ADVANCED_FILTER,
  DATE_FILTER,
  CONVERSION_WINDOW_FILTER,
  MEDIATYPE_FILTER,
  CREATIVE_FILTER,
  AUDIENCE_FILTER,
  AD_FILTER,
  KEYWORD_FILTER,
  CONVERGED_TV_SOLUTION_ID,
  SEARCH_SOLUTION_ID,
  META_SOLUTION_ID,
  DEFAULT_CONVERSION_WINDOW,
  DEFAULT_CONVERSION_WINDOW_UNIT,
  DEFAULT_CONVERSION_WINDOW_DISPLAY,
  DEFAULT_METHODOLOGY,
  MNI_MOTTO_SOLUTION_ID,
} from '@/constant';
import {
  getLabel,
  getCampaignDateRange,
  isFilterOptionDisabled,
  isFilterOptionEnabled,
  isDemoInstance,
  generateDefaultDates,
} from '@/util/utility-functions';
import { buildQueryString, setCacheFilters } from '@/helpers/global/url-helpers';
import { buildProcessedEvent } from '@/helpers/global/event-helpers';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import advertiserReportsApi from '@/api/advertiser-reports';
import DropdownCheckboxSearch from './elements/b-auto-complete-dropdown3.vue';
import fetchCampaignsMethodMixin from '~/components/mixins/fetch-campaigns-method-mixin';
import bDatepicker from '~/components/elements/b-datepicker.vue';

// import AdvancedFilters from './elements/advanced-filters.vue';
import AdvancedFilters2 from './elements/advanced-filters2.vue';
import BSelectCheckbox from './elements/b-select-checkbox.vue';
// import BDropdown from '~/components/elements/b-dropdown.vue';
import appConsoleDataLayer from '~/api/app-console-data';
import fetchPublishersMixin from './mixins/fetch-publishers-mixin';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import fetchIosMethodMixin from './mixins/fetch-ios-method-mixin';
import fetchEventsMethodMixin from './mixins/fetch-events-method-mixin';
import config from '~/config';
import store from '~/store';

export default {
  components: {
    BRadioSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox2.vue'
      ),
    // SwitchAccount: () =>
    //   import(/* webpackChunkName: "b-dropdown-select-account" */ './switch-account.vue'),
    BRadioSelectCheckboxForEvent: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
      ),
    bDatepicker,
    // AdvancedFilters,
    AdvancedFilters2,
    // BDropdown,
    BSelectCheckbox,
    DropdownCheckboxSearch,
  },
  mixins: [
    fetchCampaignsMethodMixin,
    gaEventsMixin,
    fetchIosMethodMixin,
    fetchEventsMethodMixin,
    fetchPublishersMixin,
  ],
  props: {
    isLeftNavCollapsed: {
      type: Boolean,
      required: true,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: true,
    },
    hideFilterBar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      required: false,
      default: () => '',
      type: String,
    },
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
    filtersToHide: {
      type: Array,
      required: false,
      default: () => [],
    },
    isMniReportDashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdvanceFilterInOverview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const disableCampaignFilter = isFilterOptionDisabled(this.filtersToHide, CAMPAIGN_FILTER);
    const disableAdGroupFilter = isFilterOptionDisabled(this.filtersToHide, ADGROUP_FILTER);
    const disablePixelFilter = isFilterOptionDisabled(this.filtersToHide, PIXEL_FILTER);
    const disableAdvancedFilter = isFilterOptionDisabled(this.filtersToHide, ADVANCED_FILTER);
    const disableDateFilter = isFilterOptionDisabled(this.filtersToHide, DATE_FILTER);
    const showConversionWindowFilter = isFilterOptionEnabled(
      this.filtersToHide,
      CONVERSION_WINDOW_FILTER
    );
    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'keywordOptions',
      'pixelOptions',
    ];
    return {
      initilCampaignSet: false,
      initilAdGroupSet: false,
      showFullView: true,
      showAccounts: false,
      filters: this.genFilters(),
      advancedFilters: this.genFilters(),
      isAdGroupLoading: false,
      showAdvancedFilters: false,
      dateLoading: false,
      isAdvanceFilterLoading: false,
      disableCampaignFilter,
      disableAdGroupFilter,
      disablePixelFilter,
      disableAdvancedFilter,
      disableDateFilter,
      showConversionWindowFilter,
      timeoutId: null,
      filtersModified: false,
      daysToconvertOptions: MAX_DAYS_TO_CONVERT,
      labels: {},
      selectedFilters: this.getSelectedFilters(this.genFilters(), filterOptionsArray),
      showTopNavigation: false,
      selectedSubMenuOption: 'dashboardPage',
      eventLoadingForMni: false,
      isNewReportBuilder: false,
      onSelectEventsMniPayload: [],
    };
  },
  computed: {
    account: get('common/account'),
    dates: get('dashboard/dates'),
    isMniAccount: get('common/isMniAccount'),
    isIncrementalityInMni: get('common/isIncrementalityInMni'),
    mniTemplateId: get('common/mniTemplateId'),
    isViaAccount: get('common/isViaAccount'),
    eventOptions: get('dashboard/filters@eventOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    publisherOptions: get('dashboard/filters@publisherOptions'),
    pixelOptions: get('dashboard/filters@pixelOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    isConversionDisabled: get('dashboard/isConversionDisabled'),
    keywordOptions: get('dashboard/filters@keywordOptions'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    advertiser: get('common/advertiser'),
    filtersApplied: get('dashboard/filtersApplied'),
    filtersAppliedLoading: get('dashboard/filtersAppliedLoading'),
    selectedDashboardTab: get('dashboard/selectedDashboardTab'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    ioOptions: get('dashboard/filters@ioOptions'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    universalPixelId: get('common/universalPixelId'),
    dashboardTabAndAdvFilterOptions() {
      return `${this.selectedDashboardTab}|${this.filtersToHide.join(',')}`;
    },
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    appliedFilterCount() {
      if (this.filtersToHide.includes(ADVANCED_FILTER)) {
        this.resetFilters();
        return 0;
      }
      if (this.selectedDashboardTab === 'journeyanalytics') {
        this.resetFilters();
        return 0;
      }
      const filters = this.$store.copy('dashboard/filters');
      let appliedCount = 0;
      let filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'creativeOptions',
        'audienceOptions',
        'keywordOptions',
      ];
      // Google and Meta solutions have their media type options hidden so it should not be included in count
      if ([SEARCH_SOLUTION_ID, META_SOLUTION_ID].includes(this.activeSolutionId)) {
        filterKeys = filterKeys.filter((item) => item !== 'mediaTypeOptions');
      }
      filterKeys.forEach((key) => {
        if (filters[key]?.filter((v) => v.checked)?.length > 0) {
          appliedCount += filters[key]?.filter((v) => v.checked)?.length ? 1 : 0;
        }
      });
      return this.activeSolutionId === CONVERGED_TV_SOLUTION_ID &&
        this.showConversionWindowFilter &&
        filters?.conversionWindow > 0
        ? appliedCount + 1
        : appliedCount;
    },
  },
  watch: {
    title(n, o) {
      if (!_.isEqual(n, o)) {
        if (this.isMniAccount) {
          this.$store.set('dashboard/selectedDashboardTab', 'overview');
          this.$emit('show-pixel-page', false);
          this.selectedSubMenuOption = 'dashboardPage';
        }
      }
    },
    hideFilterBar(n, o) {
      if (!_.isEqual(n, o)) {
        if (this.isMniAccount && this.selectedSubMenuOption === 'pixelPage') {
          this.$emit('show-pixel-page', false);
          this.selectedSubMenuOption = 'dashboardPage';
        }
      }
    },
    campaignOptions(n) {
      this.filters.campaignOptions = JSON.parse(JSON.stringify(n));
      this.selectedFilters.campaignOptions = this.filters.campaignOptions.filter(
        (item) => item.checked
      );
    },
    ioOptions(n) {
      this.filters.ioOptions = JSON.parse(JSON.stringify(n));
      this.selectedFilters.ioOptions = this.filters.ioOptions.filter((item) => item.checked);
    },
    mediaTypeOptions(n) {
      this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
    },
    creativeOptions(n) {
      this.filters.creativeOptions = JSON.parse(JSON.stringify(n));
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    adGroupOptions(n) {
      this.filters.adGroupOptions = JSON.parse(JSON.stringify(n));
      this.selectedFilters.adGroupOptions = this.filters.adGroupOptions.filter(
        (item) => item.checked
      );
    },
    pixelOptions(n) {
      this.filters.pixelOptions = JSON.parse(JSON.stringify(n));
      this.selectedFilters.pixelOptions = this.filters.pixelOptions.filter((item) => item.checked);
    },
    audienceOptions(n) {
      this.filters.audienceOptions = JSON.parse(JSON.stringify(n));
    },
    publisherOptions(n) {
      if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
        this.filters.publisherOptions = JSON.parse(JSON.stringify(n));
      }
    },
    filtersToHide(n) {
      this.disableCampaignFilter = isFilterOptionDisabled(n, CAMPAIGN_FILTER);
      this.disableAdGroupFilter = isFilterOptionDisabled(n, ADGROUP_FILTER);
      this.disablePixelFilter = isFilterOptionDisabled(n, PIXEL_FILTER);
      this.disableAdvancedFilter = isFilterOptionDisabled(n, ADVANCED_FILTER);
      this.disableDateFilter = isFilterOptionDisabled(n, DATE_FILTER);
      this.showConversionWindowFilter = isFilterOptionEnabled(n, CONVERSION_WINDOW_FILTER);
    },
    dashboardTabAndAdvFilterOptions(n, o) {
      if (!_.isEqual(n, o)) {
        this.resetFilters();
      }
    },
    selectedDashboardTab(n, o) {
      this.updateFiltersToHide();
      if (!_.isEqual(n, o)) {
        if (this.selectedDashboardTab === 'journeyanalytics') {
          this.resetFilters();
          this.daysToconvertOptions = MAX_DAYS_TO_CONVERT_JOURNEY;
          const selectedConversion = this.selectConversionValue();
          this.onSelectDayToConvert(selectedConversion);
        } else {
          this.daysToconvertOptions = MAX_DAYS_TO_CONVERT;
          const selectedConversion = this.selectConversionValue();
          this.onSelectDayToConvert(selectedConversion);
        }
      }
    },
    activeSolutionId(n, o) {
      if (!_.isEqual(n, o)) {
        this.updateFiltersToHide();
        // this.dates = get('dashboard/dates');
        if (!isDemoInstance()) {
          const { startDate, endDate, dateRangeOption, compareWith } = generateDefaultDates();
          const newDates = { ...this.dates, startDate, endDate, dateRangeOption, compareWith };
          this.$store.set('dashboard/dates', {
            ...this.dates,
            ...newDates,
          });
        }
      }
    },
    eventOptions(n) {
      if (this.isMniReportDashboard) {
        this.filters.eventOptions = JSON.parse(JSON.stringify(n));
      }
    },
  },
  created() {
    this.onSelectEventsMniPayload = [];
    EventBus.$on('show-advance-filter', this.onShowAdvanceFilterInOverview);
    if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
      EventBus.$on('get-creative-options', this.callGetCreativeOptions);
    }
    window.addEventListener('click', () => {
      const datePickerElement = document.querySelectorAll('[id*="datepicker_"]');
      if (datePickerElement) {
        if (this.isLeftNavCollapsed) {
          datePickerElement.forEach((elm) => elm.classList.add('move-date-picker'));
        }
        if (!this.isLeftNavCollapsed) {
          datePickerElement.forEach((elm) => elm.classList.remove('move-date-picker'));
        }
        if (this.isCampaignTableExpanded) {
          datePickerElement.forEach((elm) => elm.classList.add('move-ext-date-picker'));
        } else {
          datePickerElement.forEach((elm) => elm.classList.remove('move-ext-date-picker'));
        }
      }
    });
  },
  async mounted() {
    EventBus.eventsLoaded = false;
    this.$emit('show-pixel-page', false);
    this.selectedSubMenuOption = 'dashboardPage';

    this.onSelectEventsMniPayload = [];

    if (this.title === 'Media Now Interactive // Motto Reporting') {
      this.$store.dispatch('common/setIsIncrementalityInMni', true);
      this.$store.set('dashboard/selectedDashboardTab', 'overview');
      this.updateFilterCount();
    } else {
      // if not motto reporting page then incrementality widge is not in MNI mark it as false  in top-filter section since it is common for all
      this.$store.dispatch('common/setIsIncrementalityInMni', false);
    }

    if (this.isMniAccount) {
      this.eventLoadingForMni = true;
      await this.callFetchEvents(); // fetch events data.

      this.showTopNavigation = true;
      this.changeSelectedSubMenuOption('dashboardPage');
    } else {
      this.showTopNavigation = false;
    }

    if (!this.audienceOptions.length && this.activeSolutionId === MNI_MOTTO_SOLUTION_ID) {
      const campaigns = this.campaignOptions;
      const dateRange = this.$store.get('dashboard/dates');
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaigns,
        adGroups: '',
        startDate: formatDateForAPI(dateRange?.startDate),
        endDate: formatDateForAPI(dateRange?.endDate),
      };
      await this.fetchAudiences(this.advertiserId, this.activeSolutionId, payload);
    }

    if (!this.campaignOptions.length) {
      this.dateLoading = true;
      if (!this.isMniReportDashboard) {
        const filterFromStorage = localStorage.getItem('filters_cache_filterCacheId');
        if (filterFromStorage === undefined || filterFromStorage === null) {
          await this.fetchCampaignOptions(this.activeSolutionId);
        }
      }
      this.dateLoading = false;
      if (this.selectedDashboardTab === 'journeyanalytics') {
        this.daysToconvertOptions = MAX_DAYS_TO_CONVERT_JOURNEY;
        this.onSelectDayToConvert(this.daysToconvertOptions, true);
        //  const selectedConversion = this.selectConversionValue();
        //   this.onSelectDayToConvert(selectedConversion);
      } else {
        this.daysToconvertOptions = MAX_DAYS_TO_CONVERT;
        this.onSelectDayToConvert(this.daysToconvertOptions, true);
        //  const selectedConversion = this.selectConversionValue();
        // this.onSelectDayToConvert(selectedConversion);
      }
    }
    this.labels = getLabel('topFilterSection', this.account.id);
    if (!this.isHidden) {
      if (this.isMniAccount) {
        this.setDate();
      } else {
        let computedDate = get('dashboard/dates');
        if (!isDemoInstance()) {
          const { startDate, endDate, dateRangeOption, compareWith } = generateDefaultDates();
          const newDates = { ...computedDate, startDate, endDate, dateRangeOption, compareWith };
          this.$store.set('dashboard/dates', {
            ...computedDate,
            ...newDates,
          });
        } else {
          const { startDate, endDate, dateRangeOption } = this.dates;
          computedDate = { startDate, endDate, dateRangeOption };
          this.$store.set('dashboard/dates', {
            ...computedDate,
            ...this.filters.dateRange,
          });
        }
      }
    }

    if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
      await this.fetchPublishers();
    }

    if (this.isMniReportDashboard) {
      this.$store.dispatch('dashboard/resetEventOptions');

      this.mniAccount = true;
      this.$store.dispatch('common/setIsMniAccount', true);
      this.onSelectDayToConvert(this.daysToconvertOptions, true);

      await this.fetchIos();
      // await this.fetchEvents();

      const selectedIoOptions = this.ioOptions.filter((io) => io.checked);
      if (selectedIoOptions.length === 1) {
        await this.singleCampaignSettings(selectedIoOptions[0].campaignId, true);
      } else {
        this.defaultCampaignSettings(true);
      }
    }
    // for initility when no filters are modified dropdown close should not make any Unneccesary API call.
    this.filtersModified = false;

    if (this.isMniReportDashboard) {
      this.$nextTick(() => {
        EventBus.$emit('do-report-search', true);
      });
    }
    EventBus.eventsLoaded = true;
    this.eventLoadingForMni = false;
  },
  beforeDestroy() {
    this.$emit('show-pixel-page', false);
    this.selectedSubMenuOption = 'dashboardPage';
    EventBus.$off('show-advance-filter', this.onShowAdvanceFilterInOverview);
    if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
      EventBus.$off('get-creative-options', this.callGetCreativeOptions);
    }
  },
  methods: {
    async callFetchEvents() {
      if (EventBus.universalPixelIdReady) {
        await this.fetchEvents();

        if (this.title === 'Media Now Interactive // Motto Reporting') {
          // if MNI reporting we dont want video completions and  clicks in tablue reports
          const events = this.eventOptions.filter(
            (event) => event.category !== 'ctr' && event.category !== 'vcr'
          );
          this.$store.set('dashboard/filters@eventOptions', events);
          EventBus.eventsLoaded = true;
          this.eventLoadingForMni = false;
        }
      } else {
        setTimeout(() => this.callFetchEvents(), 200);
      }
    },
    onShowAdvanceFilterInOverview(filtersFromOverview) {
      this.handleShowAdvancedFilters(filtersFromOverview);
    },
    callGetCreativeOptions(selectedCreativeOptions, campaignIds) {
      this.getCreativesBasedOnCampaigns(selectedCreativeOptions, campaignIds);
    },
    async getCreativesBasedOnCampaigns(selectedCreativeOptions, campaignIds) {
      if (campaignIds && campaignIds.length > 0) {
        let resultIds = '';
        resultIds =
          campaignIds
            ?.map((res) => {
              return res.key;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        let dateRange = getCampaignDateRange(campaignIds, true);
        if (isDemoInstance()) {
          dateRange = store.get('dashboard/dates');
        }
        await this.getCreatives(resultIds, '', dateRange, selectedCreativeOptions);
      }
    },
    onEventDropdownClose() {
      if (this.onSelectEventsMniPayload && this.onSelectEventsMniPayload.length > 0) {
        this.filtersModified = true;
        this.$store.set(
          'dashboard/filters@eventOptions',
          JSON.parse(JSON.stringify(this.onSelectEventsMniPayload))
        );
        if (!this.filtersModified) {
          return;
        }
      }

      this.$store.set('dashboard/filtersApplied', true);
      this.$store.set('dashboard/filtersAppliedLoading', true);
      this.filtersModified = false;
    },
    onSelectEventsMni(payload) {
      this.onSelectEventsMniPayload = payload;
    },
    setDate() {
      const storedDate = sessionStorage.getItem('selectedDates');
      if (storedDate !== undefined && storedDate !== null) {
        this.$store.set('dashboard/dates', JSON.parse(storedDate));
      } else {
        let computedDate = get('dashboard/dates');
        if (isDemoInstance()) {
          const { startDate, endDate, dateRangeOption } = this.dates;
          computedDate = { startDate, endDate, dateRangeOption };
          this.$store.set('dashboard/dates', {
            ...computedDate,
            ...this.filters.dateRange,
          });
        } else {
          const { startDate, endDate, dateRangeOption, compareWith } = generateDefaultDates();
          const newDates = { ...computedDate, startDate, endDate, dateRangeOption, compareWith };
          this.$store.set('dashboard/dates', {
            ...computedDate,
            ...newDates,
          });
        }
      }
    },
    changeSelectedSubMenuOption(page) {
      this.selectedSubMenuOption = page;
      if (page === 'pixelPage') {
        this.$emit('show-pixel-page', true);
      } else {
        this.$emit('show-pixel-page', false);
        // set back date
        this.setDate();
      }
    },
    getSelectedFilters(filters, filterOptionsArray) {
      const selectFilterVals = {};
      filterOptionsArray?.forEach((fl) => {
        if (filters[fl]?.length) {
          selectFilterVals[fl] = filters[fl]?.filter((f) => f.checked);
        } else {
          selectFilterVals[fl] = [];
        }
      });
      return JSON.parse(JSON.stringify(selectFilterVals));
    },
    onSelectDayToConvert(data, isMounted = false) {
      const daysToConvert = data.filter((item) => item.checked);
      if (this.isMniReportDashboard || this.isIncrementalityInMni) {
        this.filters.conversionWindow = daysToConvert[0].value * 24;
        this.$store.set('dashboard/filters@conversionWindow', this.filters.conversionWindow);
        if (!isMounted) {
          this.$nextTick(() => {
            EventBus.$emit('do-report-search', true);
          });
        }
      } else {
        this.filters.conversionWindow = daysToConvert[0].value;
        this.$store.set('dashboard/filters@conversionWindow', this.filters.conversionWindow);
      }
    },
    handleShowAdvancedFilters(filtersFromOverview = null) {
      if (filtersFromOverview?.isNewReportBuilder) {
        this.isNewReportBuilder = filtersFromOverview.isNewReportBuilder;
      } else {
        this.isNewReportBuilder = false;
      }
      this.showAdvancedFilters = false;
      this.advancedFilters = this.genFilters(filtersFromOverview);
      this.showAdvancedFilters = true;
      this.toggleScroll(false);
    },
    toggleScroll(isScrolling) {
      const root = document.querySelector(':root');
      if (isScrolling) {
        root.style.overflowY = 'scroll';
      } else {
        root.style.overflowY = 'hidden';
      }
    },

    onCloseAdvanceFilters() {
      this.advancedFilters.campaignOptions = this.filters.campaignOptions;
      this.advancedFilters.adGroupOptions = this.filters.adGroupOptions;
      this.advancedFilters.pixelOptions = this.filters.pixelOptions || [];
      this.advancedFilters.mediaTypeOptions = this.filters.mediaTypeOptions || [];
      this.advancedFilters.audienceOptions = this.filters.audienceOptions || [];
      this.advancedFilters.creativeOptions = this.filters.creativeOptions || [];
      this.advancedFilters.keywordOptions = this.filters.keywordOptions || [];
      this.advancedFilters.conversionWindow =
        this.filters.conversionWindow || DEFAULT_CONVERSION_WINDOW;
      if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
        this.advancedFilters.ioOptions = this.filters.ioOptions;
        this.advancedFilters.publisherOptions = this.filters.publisherOptions;
      }
      this.showAdvancedFilters = false;
      this.toggleScroll(true);
    },
    onAdvanceFilterApply(applyFilters) {
      if (!_.isEqual(this.filters, applyFilters)) {
        if (
          this.isMniReportDashboard ||
          (!this.isAdvanceFilterInOverview && !this.isMniReportDashboard)
        ) {
          this.$store.set('dashboard/filtersAppliedLoading', true);
          this.$store.set('dashboard/filters@mediaTypeOptions', applyFilters.mediaTypeOptions);
          this.$store.set('dashboard/filters@adGroupOptions', applyFilters.adGroupOptions);
          this.selectedFilters.adGroupOptions = applyFilters.adGroupOptions.filter(
            (item) => item.checked
          );
          this.selectedFilters.campaignOptions = applyFilters.campaignOptions.filter(
            (item) => item.checked
          );
          this.$store.set(
            'dashboard/filters@ioOptions',
            applyFilters.campaignOptions.map((option) => ({ ...option, disabled: false }))
          );
          this.$store.set(
            'dashboard/filters@campaignOptions',
            applyFilters.campaignOptions.map((option) => ({ ...option, disabled: false }))
          );
          this.$store.set('dashboard/filters@pixelOptions', applyFilters.pixelOptions);
          this.$store.set('dashboard/filters@audienceOptions', applyFilters.audienceOptions);
          this.$store.set('dashboard/filters@creativeOptions', applyFilters.creativeOptions);
          this.$store.set('dashboard/filters@keywordOptions', applyFilters.keywordOptions);
          this.$store.set('dashboard/filters@conversionWindow', applyFilters.conversionWindow);

          this.$store.set('dashboard/filtersApplied', true);
          this.$store.set('dashboard/dates', {
            ...this.dates,
            ...applyFilters.dateRange,
          });
          this.filters = this.genFilters();
          this.advancedFilters = this.genFilters();
          this.updateFilterCount();

          if (this.isMniReportDashboard) {
            this.$nextTick(() => {
              EventBus.$emit('do-report-search', true);
            });
          } else if (this.isNewReportBuilder) {
            EventBus.$emit('advance-filter-on-apply', applyFilters);
          }
        } else {
          EventBus.$emit('advance-filter-on-apply', applyFilters);
        }
      }
      this.showAdvancedFilters = false;
      this.toggleScroll(true);
    },
    bDatepickerChanges(data) {
      const datePickerPayload = {
        startDate: data.dates[0],
        endDate: data.dates[1],
        compareWith: data.compareWith,
        compareStartDate: data.dates[2],
        compareEndDate: data.dates[3],
        dateRangeOption: data.dateRangeOption,
        dateCompareOption: data.dateCompareOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      if (this.isMniAccount) {
        sessionStorage.setItem('selectedDates', JSON.stringify(datePickerPayload));
      }
      this.getConversionPixelOptionsApi();
      this.datePickerChangedGAEvent(datePickerPayload);
      this.$store.set('dashboard/preventDatesLoading', false);
      this.$forceUpdate();
      if (this.isMniReportDashboard) {
        this.$nextTick(() => {
          EventBus.$emit('do-report-search', true);
        });
      }
    },
    datePickerChangedGAEvent(datePickerPayload) {
      if (config.gaEnabled()) {
        const {
          startDate,
          endDate,
          compareWith,
          compareStartDate,
          compareEndDate,
          dateRangeOption,
          dateCompareOption,
        } = datePickerPayload;

        const gaEv = {
          start_date: startDate,
          end_date: endDate,
          compare: compareWith,
          date_range_option: DATEPICKER_RANGE_OPTIONS[`${dateRangeOption}`],
        };

        if (compareWith) {
          gaEv.compare_start_date = compareStartDate;
          gaEv.compare_end_date = compareEndDate;
          gaEv.compare_date_option = DATEPICKER_COMPARE_RANGE_OPTIONS[`${dateCompareOption}`];
        }
        this.fireGAEvent('drsd_dtpk_change', gaEv);
      }
    },
    toggleAccountSwitcher(val) {
      this.showAccounts = val;
    },
    resetFilters() {
      this.filters = this.genFilters();
      this.advancedFilters = this.genFilters();
    },
    genFilters(filtersFromOverview = null) {
      let ioOpts = this.$store.copy('dashboard/filters@ioOptions');
      let adGroupOpts = this.$store.copy('dashboard/filters@adGroupOptions');
      let mediaOpts = this.$store.copy('dashboard/filters@mediaTypeOptions');
      let creativOpts = this.$store.copy('dashboard/filters@creativeOptions');
      let audienceOpts = this.$store.copy('dashboard/filters@audienceOptions');
      let publisherOpts = this.$store.copy('dashboard/filters@publisherOptions');

      if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard && filtersFromOverview) {
        ioOpts = filtersFromOverview?.ioOptions ? filtersFromOverview?.ioOptions : ioOpts;
        adGroupOpts = filtersFromOverview?.adGroupOptions
          ? filtersFromOverview?.adGroupOptions
          : adGroupOpts;
        mediaOpts = filtersFromOverview?.mediaTypeOptions
          ? filtersFromOverview?.mediaTypeOptions
          : mediaOpts;
        creativOpts = filtersFromOverview?.creativeOptions
          ? filtersFromOverview?.creativeOptions
          : creativOpts;
        audienceOpts = filtersFromOverview?.audienceOptions
          ? filtersFromOverview?.audienceOptions
          : audienceOpts;
        publisherOpts = filtersFromOverview?.publisherOptions
          ? filtersFromOverview?.publisherOptions
          : publisherOpts;
      }
      return {
        ioOptions: ioOpts,
        campaignOptions: this.$store.copy('dashboard/filters@campaignOptions'),
        adGroupOptions: adGroupOpts,
        pixelOptions: this.$store.copy('dashboard/filters@pixelOptions'),
        mediaTypeOptions: mediaOpts,
        audienceOptions: audienceOpts,
        creativeOptions: creativOpts,
        publisherOptions: publisherOpts,
        keywordOptions: this.$store.copy('dashboard/filters@keywordOptions'),
        conversionWindow: this.$store.copy('dashboard/filters@conversionWindow'),
      };
    },

    onCloseAdGroupDropdown() {
      if (!this.filtersModified) return;
      const data = this.filters.adGroupOptions;
      this.$store.set('dashboard/filters@adGroupOptions', data);
      this.$store.set('dashboard/filtersApplied', true);
      this.filtersModified = false;
    },

    onCloseAdGroupDropdownVia() {
      const data = this.filters.adGroupOptions;
      this.$store.set('dashboard/filters@adGroupOptions', data);
      this.$store.set('dashboard/filtersApplied', true);
      this.filtersModified = false;
    },
    onCloseAudienceOptionsDropdownMni() {
      if (!this.filtersModified) return;
      const data = this.filters.audienceOptions;
      this.$store.set('dashboard/filters@audienceOptions', data);
      this.filtersModified = false;
    },
    async onSelectAdGroups(data) {
      this.filtersModified = true;
      this.filters.adGroupOptions = data;
      this.advancedFilters.adGroupOptions = data;
    },
    onCloseConversionsDropdown() {
      if (this.areArraysEqualSelected(this.filters.pixelOptions, this.pixelOptions)) {
        return;
      }
      if (!this.filtersModified) return;
      const data = this.filters.pixelOptions;
      this.$store.set('dashboard/filters@pixelOptions', data);
      this.$store.set('dashboard/filtersApplied', true);
      this.filtersModified = false;
    },
    areArraysEqualSelected(arr1, arr2) {
      // Check if arrays have the same length
      if (arr1.length !== arr2.length) {
        return false;
      }

      // Create a map from the first array based on the key and checked status
      const map1 = arr1.reduce((acc, item) => {
        acc[item.key] = item.checked;
        return acc;
      }, {});

      // Compare the checked status of items in the second array with the map from the first array
      // eslint-disable-next-line no-restricted-syntax
      for (const item of arr2) {
        if (map1[item.key] !== item.checked) {
          return false;
        }
      }

      return true;
    },
    async onSelectPixels(data) {
      this.filtersModified = true;
      this.filters.pixelOptions = data;
      this.advancedFilters.pixelOptions = data;
    },
    async getAdvancedFilterData(name, selectedData) {
      let campaignIds = '';
      let adGroups = '';
      if (name === 'campaignOptions') {
        this.advancedFilters.campaignOptions = selectedData;
        campaignIds = this.getCampaignIds(selectedData);
        this.getAdGroups(selectedData);
      } else if (name === 'ioOptions') {
        this.advancedFilters.ioOptions = selectedData;
        campaignIds = this.getCampaignIds(selectedData);
        this.getAdGroups(selectedData);
      } else {
        this.advancedFilters.adGroupOptions = selectedData;
        campaignIds = this.getCampaignIds(
          !this.isAdvanceFilterInOverview
            ? this.advancedFilters.campaignOptions
            : this.advancedFilters.ioOptions
        );
        adGroups = this.getSelectedAdGroupStr(selectedData);
      }
      let dateRange = getCampaignDateRange(
        !this.isAdvanceFilterInOverview
          ? this.advancedFilters.campaignOptions
          : this.advancedFilters.ioOptions,
        true
      );
      if (isDemoInstance()) {
        dateRange = store.get('dashboard/dates');
      }
      this.advancedFilters.dateRange = dateRange;

      this.advancedFilters.pixelOptions = [];
      this.advancedFilters.mediaTypeOptions = [];
      this.advancedFilters.audienceOptions = [];
      this.advancedFilters.creativeOptions = [];
      this.advancedFilters.keywordOptions = [];
      this.advancedFilters.publisherOptions = [];

      if (!dateRange.notAvailable) {
        this.isAdvanceFilterLoading = true;
        this.advancedFilters.pixelOptions = await this.getConversionPixelOptions(
          campaignIds,
          adGroups,
          dateRange
        );

        this.advancedFilters.mediaTypeOptions = await this.getMediaTypes(
          campaignIds,
          adGroups,
          dateRange
        );

        this.advancedFilters.audienceOptions = await this.getAudiences(
          campaignIds,
          adGroups,
          dateRange
        );

        this.advancedFilters.publisherOptions = await this.getPublishers();

        this.advancedFilters.creativeOptions = await this.getCreatives(
          campaignIds,
          adGroups,
          dateRange
        );

        this.advancedFilters.keywordOptions = await this.getKeywords(
          campaignIds,
          adGroups,
          dateRange
        );
        this.isAdvanceFilterLoading = false;
      }
    },
    updateFilterCount() {
      const { filters } = this;
      let appliedCount = 0;
      const filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'creativeOptions',
        'audienceOptions',
        'keywordOptions',
        'publisherOptions',
      ];
      // Google and Meta solutions have their media type options hidden so it should not be included in count
      filterKeys.forEach((key) => {
        if (filters[key]?.filter((v) => v.checked)?.length > 0) {
          appliedCount += filters[key]?.filter((v) => v.checked)?.length ? 1 : 0;
        }
      });
      appliedCount =
        this.activeSolutionId === CONVERGED_TV_SOLUTION_ID &&
        this.showConversionWindowFilter &&
        filters?.conversionWindow > 0
          ? appliedCount + 1
          : appliedCount;
      this.$store.dispatch('common/setAppliedFilterCount', appliedCount);
      setCacheFilters(JSON.stringify(this.advancedFilters));
    },
    getCampaignIds(selectedData) {
      let result = '';
      let campaignIds;
      result = selectedData.filter((f) => f?.checked);
      if (result.length !== selectedData.length) {
        campaignIds =
          result
            ?.map((res) => {
              return res.key;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        return campaignIds;
      }
      return campaignIds;
    },
    getSelectedAdGroupStr(selectedData) {
      const adGroupOptions = selectedData;
      if (adGroupOptions.length === 0) {
        return '';
      }
      const selectedAdGroupOptions = adGroupOptions.filter((f) => f.checked);
      if (!(selectedAdGroupOptions.length === adGroupOptions.length)) {
        const queryStrArray = [];
        selectedAdGroupOptions.forEach((adGroup) => {
          const data = adGroup?.liData !== undefined ? adGroup.liData : adGroup.adreadyIds;
          if (data && data.length) {
            data.forEach((lid) => {
              if (lid.adreadyId && lid.startDate) {
                queryStrArray.push(`${lid.adreadyId}|${lid.startDate}`);
              }
            });
          }
        });
        return queryStrArray.join(',');
      }
      return '';
    },
    async getAdGroups(campaigns) {
      let adGroupData = [];
      const data = await appConsoleDataLayer.fetchAdGroupOptions(
        this.advertiserId,
        this.activeSolutionId,
        campaigns
      );
      if (data?.length > 0) {
        adGroupData = data;
      }
      if (adGroupData.length > 0) {
        adGroupData = adGroupData.map((agd) => {
          return { ...agd, value: agd?.name || '', checked: true };
        });
      }
      this.advancedFilters.adGroupOptions = adGroupData;
    },
    async getConversionPixelOptions(campaignIds, adGroups, dateRange) {
      const dates = dateRange === undefined ? this.dates : dateRange;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds,
        adGroups,
        startDate: formatDateForAPI(dates?.startDate),
        endDate: formatDateForAPI(dates?.endDate),
      };
      const data = await appConsoleDataLayer.fetchConversionPixelOptions(
        this.advertiserId,
        this.activeSolutionId,
        payload
      );
      return data;
    },
    async getMediaTypes(campaignIds, adGroups, dateRange) {
      const dates = dateRange === undefined ? this.dates : dateRange;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds,
        adGroups,
        startDate: formatDateForAPI(dates?.startDate),
        endDate: formatDateForAPI(dates?.endDate),
      };
      const data = await appConsoleDataLayer.fetchMediaTypeOptions(
        this.advertiserId,
        this.activeSolutionId,
        payload
      );
      const newData = [];
      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          newData.push({ value: data[i], checked: true });
        }
      }
      return newData;
    },
    async getKeywords(campaignIds, adGroups, dateRange) {
      if (this.activeSolutionId === CONVERGED_TV_SOLUTION_ID) {
        return [];
      }

      const dates = dateRange === undefined ? this.dates : dateRange;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds,
        adGroups,
        startDate: formatDateForAPI(dates?.startDate),
        endDate: formatDateForAPI(dates?.endDate),
      };
      const data = await appConsoleDataLayer.fetchKeywordOptions(
        this.advertiserId,
        this.activeSolutionId,
        payload
      );
      const newData = [];
      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          newData.push({ value: data[i], checked: false });
        }
      }
      return newData;
    },
    async getAudiences(campaignIds, adGroups, dateRange) {
      const dates = dateRange === undefined ? this.dates : dateRange;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds,
        adGroups,
        startDate: formatDateForAPI(dates?.startDate),
        endDate: formatDateForAPI(dates?.endDate),
      };
      const data = await appConsoleDataLayer.fetchAudienceOptions(
        this.advertiserId,
        this.activeSolutionId,
        payload
      );
      const keysSet = new Set();
      const filterOptions = [];
      // filter out duplicates
      data?.forEach((d) => {
        if (!keysSet.has(d.key)) {
          keysSet.add(d.key);
          filterOptions.push({ key: d.value, value: d.key, checked: false });
        }
      });
      return filterOptions;
    },
    async getPublishers() {
      const data = await advertiserReportsApi.publishers(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
        })
      );
      const filterOptions = [];
      if (data.length) {
        data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
      }
      return filterOptions;
    },
    async getCreatives(campaignIds, adGroups, dateRange, selectedCreativeOptions = null) {
      const dates = dateRange === undefined ? this.dates : dateRange;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds,
        adGroups,
        startDate: formatDateForAPI(dates?.startDate),
        endDate: formatDateForAPI(dates?.endDate),
      };
      const data = await appConsoleDataLayer.fetchCreativeOptions(
        this.advertiserId,
        this.activeSolutionId,
        payload
      );

      const filterOptions = [];

      if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
        const dates2 = this.dates;
        const payload2 = {
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          campaignIds,
          adGroups,
          startDate: formatDateForAPI(dates2?.startDate),
          endDate: formatDateForAPI(dates2?.endDate),
        };
        const data2 = await appConsoleDataLayer.fetchCreativeOptions(
          this.advertiserId,
          this.activeSolutionId,
          payload2
        );
        // Create a set of keys from list2
        const list2Keys = new Set(data2.map((item) => item.key));
        // Compare and flag items in list1
        const updatedList1 = data.map((item) => ({
          ...item,
          disabled: !list2Keys.has(item.key),
        }));

        const sortedList1 = updatedList1.sort((a, b) => {
          if (!a.disabled && b.disabled) return -1;
          if (a.disabled && !b.disabled) return 1;
          return 0;
        });
        //

        if (sortedList1.length) {
          sortedList1?.forEach((d) => filterOptions.push({ ...d, checked: false }));
        }
        if (selectedCreativeOptions !== null) {
          if (selectedCreativeOptions.length === 0) {
            this.$nextTick(() => {
              EventBus.$emit('update-creative-options', filterOptions);
            });
          } else {
            const selectedCreativesVal = selectedCreativeOptions.map((item) => item.value);
            filterOptions.forEach((item) => {
              if (selectedCreativesVal.includes(item.value) && !item.disabled) {
                item.checked = true;
              }
            });
            this.$nextTick(() => {
              EventBus.$emit('update-creative-options', filterOptions);
            });
          }
        }
      } else if (data.length) {
        data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
      }

      return filterOptions;
    },
    onSelectMediaTypesMni(data) {
      this.filtersModified = true;
      this.filters.mediaTypeOptions = data;
    },
    onCloseMediaTypeDropdownMni() {
      if (this.filtersModified) {
        this.$store.set('dashboard/filters@mediaTypeOptions', this.filters.mediaTypeOptions);
        this.filtersModified = false;
      }
    },
    onSelectAudienceOptionsMni(data) {
      this.filtersModified = true;
      this.filters.audienceOptions = data;
    },

    onSelectCampaign(data) {
      this.filtersModified = true;
      if (!this.isAdvanceFilterInOverview) {
        this.filters.campaignOptions = data;
        if (this.isMniReportDashboard) {
          this.$store.set('dashboard/filters@ioOptions', data);
        } else {
          this.$store.set('dashboard/filters@campaignOptions', data);
        }
      } else {
        this.filters.ioOptions = data;
        this.$store.set('dashboard/filters@ioOptions', data);
      }
    },
    checkBoxSelectCampaign(data) {
      this.filtersModified = true;
      const options = !this.isAdvanceFilterInOverview
        ? this.filters.campaignOptions
        : this.filters.ioOptions;
      options.forEach((option) => {
        // Check if the current option exists in the data array
        const match = data.find((item) => item.key === option.key);

        // If there's a match, set `checked` to true, otherwise set it to false
        if (match) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });
      if (this.isMniReportDashboard) {
        this.$store.set('dashboard/filters@ioOptions', options);
      } else {
        this.$store.set('dashboard/filters@campaignOptions', options);
      }
      if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
        this.$store.set('dashboard/filters@ioOptions', options);
      }
    },
    checkBoxSelectAdGroup(data) {
      this.filtersModified = true;
      const options = this.filters.adGroupOptions;
      options.forEach((option) => {
        // Check if the current option exists in the data array
        const match = data.find((item) => item.key === option.key);

        // If there's a match, set `checked` to true, otherwise set it to false
        if (match) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });
      this.$store.set('dashboard/filters@adGroupOptions', options);
    },
    setFilterOptions(name, values) {
      this.$nextTick(() => {
        this.filters[name] = values;
        this.$forceUpdate();
      });
    },
    setSelectedFilters(name, values, isMultiSelectFilter = false) {
      if (this.initilCampaignSet && this.initilAdGroupSet) {
        this.filtersModified = true;
      }
      if (isMultiSelectFilter) {
        this.getAdvancedFilterData(name, values);
        this.setFilterOptions(name, values);
      } else {
        this.selectedFilters[name] = values.map((val) => {
          val.checked = true;
          return val;
        });
        const formattedOption = this.filters[name].map((opt) => {
          if (values.find((val) => val.value === opt.value)) {
            opt.checked = true;
          } else {
            opt.checked = false;
          }
          return opt;
        });
        this.setFilterOptions(name, formattedOption);
      }
      if (name === 'campaignOptions') {
        this.initilCampaignSet = true;
      } else if (name === 'ioOptions') {
        this.initilCampaignSet = true;
      } else if (name === 'adGroupOptions') {
        this.initilAdGroupSet = true;
      }
    },

    searchSelectCampaign(data) {
      this.filtersModified = true;
      if (!this.isAdvanceFilterInOverview) {
        this.filters.campaignOptions = data;
      } else {
        this.filters.ioOptions = data;
      }
      //  this.$store.set('dashboard/filters@campaignOptions', data);
    },
    searchSelectAdGroup(data) {
      this.filtersModified = true;
      this.filters.adGroupOptions = data;
      this.advancedFilters.adGroupOptions = data;
    },
    onSelectCampaignMni(data) {
      this.filtersModified = true;
      this.filters.campaignOptions = data;
    },

    async onCloseCampaignDropdownMni() {
      if (this.filtersModified) {
        if (this.isMniReportDashboard) {
          this.$store.set('dashboard/filters@ioOptions', this.filters.campaignOptions);
        } else {
          this.$store.set('dashboard/filters@campaignOptions', this.filters.campaignOptions);
        }
        this.$store.set('dashboard/filtersAppliedLoading', true);
        this.$store.set('dashboard/preventDatesLoading', true);
        const data = this.filters.campaignOptions;
        this.isAdGroupLoading = true;
        let result = '';
        let campaignIds = '';
        result = data.filter((f) => f?.checked);
        let adGroupData = [];
        let response = '';
        if (result.length !== data.length) {
          campaignIds =
            result
              ?.map((res) => {
                return res.key;
              })
              ?.filter((fres) => fres)
              ?.join(',') || '';
          if (campaignIds) {
            const advertiserId = this.advertiser ? this.advertiser.id : 0;
            response = await appConsoleDataLayer.fetchAdGroupOptions(
              advertiserId,
              this.activeSolutionId,
              result
            );
            if (response?.length > 0) {
              adGroupData = response;
            }
          }
        } else {
          const advertiserId = this.advertiser ? this.advertiser.id : 0;
          response = await appConsoleDataLayer.fetchAdGroupOptions(
            advertiserId,
            this.activeSolutionId
          );
          if (response?.length > 0) {
            adGroupData = response;
          }
        }
        if (adGroupData.length > 0) {
          adGroupData = adGroupData.map((agd) => {
            return { ...agd, value: agd?.name || '', checked: true };
          });
        }
        let dateRange = getCampaignDateRange(data, true);
        if (isDemoInstance()) {
          dateRange = store.get('dashboard/dates');
        }
        const adGroups = this.getSelectedAdGroupStr(adGroupData) || '';
        // Commented code is for future reference do not delete the commented code.
        // let pixelOptions = [];
        //  let mediaTypeOptions = [];
        let audienceOptions = [];
        let creativeOptions = [];
        //  let keywordOptions = [];

        if (!dateRange.notAvailable) {
          this.isAdvanceFilterLoading = true;
          //  pixelOptions = await this.getConversionPixelOptions(campaignIds, adGroups, dateRange);
          //  mediaTypeOptions = await this.getMediaTypes(campaignIds, adGroups, dateRange);
          audienceOptions = await this.getAudiences(campaignIds, adGroups, dateRange);
          creativeOptions = await this.getCreatives(campaignIds, adGroups, dateRange);
          //  keywordOptions = await this.getKeywords(campaignIds, adGroups, dateRange);
          this.isAdvanceFilterLoading = false;
          // pixelOptions = pixelOptions.map((p) => {
          //   return { ...p, checked: true };
          // });
        }
        this.isAdGroupLoading = false;
        this.filters.adGroupOptions = adGroupData;
        //  this.filters.pixelOptions = pixelOptions;
        this.advancedFilters.campaignOptions = data;
        this.$store.set('dashboard/filters@adGroupOptions', adGroupData);
        // this.$store.set('dashboard/filters@pixelOptions', pixelOptions);
        // this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOptions);
        this.$store.set('dashboard/filters@audienceOptions', audienceOptions);
        this.$store.set('dashboard/filters@creativeOptions', creativeOptions);
        //  this.$store.set('dashboard/filters@keywordOptions', keywordOptions);
        this.$store.set('dashboard/filtersApplied', true);
        this.filtersModified = false;
        this.$store.set('dashboard/filters@publisherOptions', this.filters.publisherOptions);
        this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCount);
      }
    },
    async getConversionPixelOptionsApi() {
      const data = !this.isAdvanceFilterInOverview
        ? this.filters.campaignOptions
        : this.filters.ioOptions;
      this.isAdGroupLoading = true;
      let result = '';
      let campaignIds = '';
      result = data.filter((f) => f?.checked);
      let adGroupData = [];
      let response = '';
      if (result.length !== data.length) {
        campaignIds =
          result
            ?.map((res) => {
              return res.key;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        if (campaignIds) {
          const advertiserId = this.advertiser ? this.advertiser.id : 0;
          response = await appConsoleDataLayer.fetchAdGroupOptions(
            advertiserId,
            this.activeSolutionId,
            result
          );
          if (response?.length > 0) {
            adGroupData = response;
          }
        }
      } else {
        const advertiserId = this.advertiser ? this.advertiser.id : 0;
        response = await appConsoleDataLayer.fetchAdGroupOptions(
          advertiserId,
          this.activeSolutionId
        );
        if (response?.length > 0) {
          adGroupData = response;
        }
      }
      if (adGroupData.length > 0) {
        adGroupData = adGroupData.map((agd) => {
          return { ...agd, value: agd?.name || '', checked: true };
        });
      }
      let dateRange = this.dates;
      if (isDemoInstance()) {
        dateRange = store.get('dashboard/dates');
      }
      const adGroups = this.getSelectedAdGroupStr(adGroupData) || '';
      let pixelOptions = [];
      if (!dateRange.notAvailable) {
        pixelOptions = await this.getConversionPixelOptions(campaignIds, adGroups, dateRange);
        pixelOptions = pixelOptions.map((p) => {
          return { ...p, checked: true };
        });
        this.$store.set('dashboard/filters@pixelOptions', pixelOptions);
      }
      this.isAdGroupLoading = false;
    },
    async onCloseCampaignDropdown(force = false) {
      if (!this.filtersModified && !force) return;
      this.$store.set('dashboard/filtersAppliedLoading', true);
      this.$store.set('dashboard/preventDatesLoading', true);
      const data = !this.isAdvanceFilterInOverview
        ? this.filters.campaignOptions
        : this.filters.ioOptions;
      this.isAdGroupLoading = true;
      let result = '';
      let campaignIds = '';
      result = data.filter((f) => f?.checked);
      let adGroupData = [];
      let response = '';
      if (result.length !== data.length) {
        campaignIds =
          result
            ?.map((res) => {
              return res.key;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        if (campaignIds) {
          const advertiserId = this.advertiser ? this.advertiser.id : 0;
          response = await appConsoleDataLayer.fetchAdGroupOptions(
            advertiserId,
            this.activeSolutionId,
            result
          );
          if (response?.length > 0) {
            adGroupData = response;
          }
        }
      } else {
        const advertiserId = this.advertiser ? this.advertiser.id : 0;
        response = await appConsoleDataLayer.fetchAdGroupOptions(
          advertiserId,
          this.activeSolutionId
        );
        if (response?.length > 0) {
          adGroupData = response;
        }
      }
      if (adGroupData.length > 0) {
        adGroupData = adGroupData.map((agd) => {
          return { ...agd, value: agd?.name || '', checked: true };
        });
      }
      let dateRange = getCampaignDateRange(data, true);
      if (isDemoInstance()) {
        dateRange = store.get('dashboard/dates');
      }
      const adGroups = this.getSelectedAdGroupStr(adGroupData) || '';
      let pixelOptions = [];
      let mediaTypeOptions = [];
      let audienceOptions = [];
      let creativeOptions = [];
      let keywordOptions = [];
      let publisherOptions = [];

      if (!dateRange.notAvailable) {
        this.isAdvanceFilterLoading = true;
        pixelOptions = await this.getConversionPixelOptions(campaignIds, adGroups, dateRange);
        mediaTypeOptions = await this.getMediaTypes(campaignIds, adGroups, dateRange);
        audienceOptions = await this.getAudiences(campaignIds, adGroups, dateRange);
        if (this.isAdvanceFilterInOverview && !this.isMniReportDashboard) {
          publisherOptions = await this.getPublishers();
        }
        creativeOptions = await this.getCreatives(campaignIds, adGroups, dateRange);
        keywordOptions = await this.getKeywords(campaignIds, adGroups, dateRange);
        this.isAdvanceFilterLoading = false;
        pixelOptions = pixelOptions.map((p) => {
          return { ...p, checked: true };
        });
      }
      if (force) {
        if (this.isMniReportDashboard) {
          this.$store.set('dashboard/filters@ioOptions', data);
        } else {
          this.$store.set('dashboard/filters@campaignOptions', data);
        }
      }
      this.selectedFilters.adGroupOptions = adGroupData.filter((item) => item.checked);
      if (!this.isAdvanceFilterInOverview) {
        this.selectedFilters.campaignOptions = this.filters.campaignOptions.filter(
          (item) => item.checked
        );
        this.advancedFilters.campaignOptions = data;
      } else {
        this.selectedFilters.ioOptions = this.filters.ioOptions.filter((item) => item.checked);
        this.advancedFilters.ioOptions = data;
      }

      this.isAdGroupLoading = false;
      this.filters.adGroupOptions = adGroupData;
      this.filters.pixelOptions = pixelOptions;

      this.$store.set('dashboard/filters@adGroupOptions', adGroupData);
      this.$store.set('dashboard/filters@pixelOptions', pixelOptions);
      this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOptions);
      this.$store.set('dashboard/filters@audienceOptions', audienceOptions);
      this.$store.set('dashboard/filters@creativeOptions', creativeOptions);
      this.$store.set('dashboard/filters@keywordOptions', keywordOptions);
      this.$store.set('dashboard/filtersApplied', true);
      this.filtersModified = false;
      if (!this.isAdvanceFilterInOverview) {
        this.$store.set('dashboard/filters@publisherOptions', this.filters.publisherOptions);
      } else {
        this.$store.set('dashboard/filters@publisherOptions', publisherOptions);
      }
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCount);
    },
    selectConversionValue() {
      const selectedConversionArray = this.daysToconvertOptions.map((item) => {
        if (Number(item.value) === Number(this.filters.conversionWindow)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });

      // if there is no selected value or exceeds max (going from journey analytics to other tabs), use DEFAULT_CONVERSION_WINDOW
      const CONVERSION_WINDOW = DEFAULT_CONVERSION_WINDOW;
      const trueArray = selectedConversionArray.filter((item) => item.checked === true);
      if (!trueArray.length) {
        selectedConversionArray.map((item) => {
          if (Number(item.value) === CONVERSION_WINDOW) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        });
      }
      this.daysToconvertOptions = selectedConversionArray;
      return selectedConversionArray;
    },
    updateFiltersToHide() {
      const { selectedDashboardTab, activeSolutionId } = this;
      let { filtersToHide } = this;
      if (activeSolutionId === SEARCH_SOLUTION_ID) {
        if (selectedDashboardTab === 'ad-previews') {
          const adFilter = filtersToHide.filter((item) => item === AD_FILTER);
          if (!adFilter.length) filtersToHide.push(AD_FILTER);
          const keywordFilter = filtersToHide.filter((item) => item === KEYWORD_FILTER);
          if (!keywordFilter.length) filtersToHide.push(KEYWORD_FILTER);
        }
      }
      if (activeSolutionId === CONVERGED_TV_SOLUTION_ID) {
        if (selectedDashboardTab === 'overview') {
          const adgroupFilter = filtersToHide.filter((item) => item === ADGROUP_FILTER);
          if (!adgroupFilter.length) filtersToHide.push(ADGROUP_FILTER);
          const campaignFilter = filtersToHide.filter((item) => item === CAMPAIGN_FILTER);
          if (!campaignFilter.length) filtersToHide.push(CAMPAIGN_FILTER);
          const advancedFilter = filtersToHide.filter((item) => item === ADVANCED_FILTER);
          if (!advancedFilter.length) filtersToHide.push(ADVANCED_FILTER);
          const mediaFilter = filtersToHide.filter((item) => item === MEDIATYPE_FILTER);
          if (!mediaFilter.length) filtersToHide.push(MEDIATYPE_FILTER);
          const creativeFilter = filtersToHide.filter((item) => item === CREATIVE_FILTER);
          if (!creativeFilter.length) filtersToHide.push(CREATIVE_FILTER);
          const audienceFilter = filtersToHide.filter((item) => item === AUDIENCE_FILTER);
          if (!audienceFilter.length) filtersToHide.push(AUDIENCE_FILTER);
          const conversionWindowFilter = filtersToHide.filter(
            (item) => item === CONVERSION_WINDOW_FILTER
          );
          if (!conversionWindowFilter.length) filtersToHide.push(CONVERSION_WINDOW_FILTER);
        } else if (selectedDashboardTab === 'measurement') {
          const adgroupFilter = filtersToHide.filter((item) => item === ADGROUP_FILTER);
          if (!adgroupFilter.length) filtersToHide.push(ADGROUP_FILTER);
          const mediaFilter = filtersToHide.filter((item) => item === MEDIATYPE_FILTER);
          if (!mediaFilter.length) filtersToHide.push(MEDIATYPE_FILTER);
          const creativeFilter = filtersToHide.filter((item) => item === CREATIVE_FILTER);
          if (!creativeFilter.length) filtersToHide.push(CREATIVE_FILTER);
          const audienceFilter = filtersToHide.filter((item) => item === AUDIENCE_FILTER);
          if (!audienceFilter.length) filtersToHide.push(AUDIENCE_FILTER);
          const campaignFilter = filtersToHide.filter((item) => item !== CAMPAIGN_FILTER);
          filtersToHide = campaignFilter;
        }
      }
    },
    hideCompareWith() {
      return this.isMniAccount || this.activeSolutionId === CONVERGED_TV_SOLUTION_ID;
    },
    onSelectEventDropdownClose(payload) {
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      if (!this.isAllEventsPerformanceSelected) {
        this.$store.set('dashboard/filters@eventOptions', JSON.parse(JSON.stringify(payload)));
      }
      const selectedEvent = buildProcessedEvent(payload);
      const toggleOptions = [...this.totalOrUniqueRadioOptions];

      if (!selectedEvent.isRevenueEvent) {
        toggleOptions[1].disabled = false;
      } else {
        if (this.totalOrUniqueRadioOptions[1].active) {
          toggleOptions[0].active = true;
          toggleOptions[1].active = false;
        }
        toggleOptions[1].disabled = true;
      }

      this.$store.set(
        'dashboard/filters@totalOrUniqueRadioOptions',
        JSON.parse(JSON.stringify(toggleOptions))
      );
      // this.$store.set('dashboard/filters@showUniques', false);
      this.$store.set(
        'dashboard/filters@showUniques',
        toggleOptions[1].active && !toggleOptions[1].disabled
      );
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setShowFilters', false);
      if (this.isMniReportDashboard) {
        this.$nextTick(() => {
          EventBus.$emit('do-report-search', true);
        });
      }
    },
    async singleCampaignSettings(campaignId, isMounted = false) {
      this.loadingFilters = true;
      let flipCampaignInfo = {};
      if (campaignId && !this.isMniAccount) {
        const resPlan = await planApi.read(campaignId, {
          isLineItemsRequired: false,
        });
        flipCampaignInfo = resPlan.flipCampaignInfo || {};
      }
      // check  from where the campaign goals data is coming from
      this.selectedCampaignGoal = flipCampaignInfo?.goal ? flipCampaignInfo?.goal : {};
      const conversionWindowObj = {
        name: flipCampaignInfo?.conversionWindow?.unit?.value || DEFAULT_CONVERSION_WINDOW_UNIT,
      };
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      let methodologyOption = {};
      this.filters.conversionWindowDisplay =
        flipCampaignInfo?.conversionWindow?.value || DEFAULT_CONVERSION_WINDOW_DISPLAY;
      methodologyOption = methodologyOptions.find((mo) => {
        return mo.name.toUpperCase() === flipCampaignInfo?.methodology?.value?.toUpperCase();
      });
      this.updateConversionWindowUnit(conversionWindowObj);
      this.onSelectMethodology(methodologyOption?.id || DEFAULT_METHODOLOGY);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          this.filters.conversionWindowDisplay
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowObj.name.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (conversionWindowObj.name.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set(
          'dashboard/filters@methodology',
          methodologyOption?.id || DEFAULT_METHODOLOGY
        );
      }
      this.loadingFilters = false;
    },
    defaultCampaignSettings(isMounted = false) {
      this.onSelectMethodology(DEFAULT_METHODOLOGY);
      this.updateConversionWindowUnit({
        name: DEFAULT_CONVERSION_WINDOW_UNIT,
      });
      this.filters.conversionWindowDisplay = DEFAULT_CONVERSION_WINDOW_DISPLAY;
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          DEFAULT_CONVERSION_WINDOW_DISPLAY
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set('dashboard/filters@methodology', DEFAULT_METHODOLOGY);
        this.$store.set('dashboard/filters@showUniques', false);
      }
    },
    updateConversionWindowUnit(val) {
      this.filters.conversionWindowUnit = val.name.toUpperCase();
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
  },
};
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800';

.scroll1 {
  box-shadow: 0px 3px 20px rgba(0, 20, 70, 0.25) !important;
}

.datepicker.move-date-picker {
  // left: 405px !important;
}
.datepicker.scroll-date-picker {
  position: fixed !important;
  top: 50px !important;
}
.datepicker.move-ext-date-picker {
  //left: 488px !important;
  // left: 466px !important;
  top: 48px !important;
}
.scroll2 {
  top: 0px !important;
}
.topbarscroll {
  position: relative;
  top: -70px !important;
}
div.datepicker {
  position: relative;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 12px;
  width: 768px !important;
  height: 218px !important;
  position: absolute;
  font-family: 'Manrope', sans-serif !important;
  cursor: default;
  top: 117px !important;
  //left: 374px !important;
  //left: 377px !important;
  // left: 448px !important;
  display: none;
  z-index: 999;
  margin: 10px 0px 0px 219px !important;
  border: 1px solid #c0c2ca !important;
  box-shadow: 1px 3px 6px 1px rgb(76 86 107 / 39%) !important;
  // background-color: #f6f7fa;
  // background-size: 6px 6px;
  // background-image: radial-gradient(circle, rgba(0, 0, 0, 0.04) 1px, rgba(0, 0, 0, 0) 1px);
}
div.datepicker th {
  font-weight: 500 !important;
}
.datepicker {
  background-color: #f6f7fa;
}
.compare .btn,
div.datepicker th a.datepickerMonth {
  font-family: 'Manrope', sans-serif !important;
}
.selectwrap {
  width: 203px !important;
  &::after {
    -webkit-text-stroke: 0.7px #fff !important;
  }
}
.datepickerGoPrev span:before {
  content: '\f0d9' !important;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
}
.datepickerGoNext span:before {
  content: '\f0da' !important;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
}
.drp-wrap .compare-label > span {
  font-size: 1.1em !important;
}
[data-id^='date1s'],
[data-id^='date2s'] {
  width: 89% !important;
}
[data-id^='date1e'],
[data-id^='date2e'] {
  float: left;
  width: 89% !important;
  margin-left: -22px;
}
.compare .control-label {
  font-weight: 200 !important;
}
select,
.compare input[type='text'] {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
}

.datepicker {
  border: 0px solid #cccccc;
  border-radius: 4px;
  padding: 8px 30px 10px;
}
.datepickerDays a {
  width: 20px;
  line-height: 16px;
  height: 16px;
  padding-right: 2px;
  font-weight: 300 !important;
}
div.datepicker a {
  color: #787878;
  text-decoration: none;
  cursor: default;
  outline: none;
  font-weight: 300 !important;
}
.hideTopNav {
  display: none;
}
.showTopNav {
  display: block;
}
.zeroTop {
  top: 0 !important;
}
</style>
<style lang="scss" scoped>
.applied-cnt {
  position: absolute;
  background: var(--primaryalt);
  z-index: 2;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  padding: 1px 4px 1px 4px;
  line-height: 1.3em;
  border-radius: 20px;
  text-align: center;
  margin: -6px 0px 0px -8px;
  top: 0px;
  right: -10px;
}
.ftop-icons {
  position: relative;

  display: inline-block;
  vertical-align: top;
  color: var(--primarydark2);
  cursor: pointer;
  position: relative;
  font-size: 16px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;

  .ttip-wrap {
    position: absolute;
    width: max-content;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
    padding-top: 0px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 103;
    pointer-events: none;
    left: -47px;
    top: 20px;
    .ttip {
      display: inline-block;
      position: relative;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: var(--primarydark2);
      color: #fff;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.1em;
      margin-top: 0px;
    }
    .ttip::before {
      content: '';
      display: inline-block;
      left: 50%;
      top: -5px;
      height: 0px;
      width: 0px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-bottom: solid 6px var(--primarydark2);
      position: absolute;
      margin-left: -3px;
    }
  }
  &:hover .fa-filter {
    color: var(--primarycolor);
  }
  &:hover .ttip-wrap {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 6px, 0px);
  }
}

.top-filter-bar {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-left: 279px;
  background-color: rgb(250, 250, 251);
  z-index: 102;
  min-height: 55px;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease 0s;
  border-top: 3px solid var(--primarycolor);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.transparentBg {
  background-color: transparent !important;
  display: none;
}
.text-right {
  text-align: right;
}
.tfb-content-wrap .mdn-page-title-txt {
  line-height: 1.1em;
  margin-bottom: -8px;
  font-size: 22px;
  margin-top: -7px;
}
@media (max-width: 900px) {
  .tfb-content-wrap {
    padding: 4px 20px !important;
  }
}
.tfb-content-wrap {
  padding-right: 17px;
  padding-left: 320px;
  max-width: 1430px !important;
  margin: auto;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  // padding-top: 4px;
  // padding-bottom: 4px;
  .tfb-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 63px;
    .tfb_left {
      //width: calc(100% - 240px);
      //margin-left: 1.9%;
      margin-left: 0;
      .mdn-page-title-txt {
        line-height: 1.1em;
        margin-bottom: -8px;
        font-size: 22px;
        margin-top: -7px;
      }
      .move-title {
        margin-left: 20.1%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    .tfb_right {
      width: 240px;
      text-align: right;
      padding-right: 100px;
      .tp_acct_wrap {
        display: inline-block;
        margin-left: 34%;
        vertical-align: top;
        margin-top: -5px;
        min-width: 160px;
        text-align: right;
        position: relative;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &.move-acct-wrap {
          //margin-left: 64px;
          margin-left: 44%;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.sclosed .tfb-content-wrap {
  padding: 0 31px 0 37px;
}

@media (max-width: 900px) {
  .filter-and-date-tabs {
    padding: 4px 20px !important;
  }
}
.filter-and-date-tabs {
  padding: 0px 35px 0px 325px;
  transition: all 0.3s ease;
  max-width: 1430px;
  margin: auto;
  position: relative;
}
.sclosed .filter-and-date-tabs {
  transition: all 0.3s ease;
  padding-left: 97px;
  margin: auto;
  max-width: 1340px;
  padding-right: 43px;
}
.sub_filters_bar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sub_filters_bar .move-filter-tab {
  margin-left: auto !important;
}
@media (max-width: 900px) {
  .b-datepicker-container .wrap {
    margin-right: 0px !important;
  }
}
.b-datepicker-container {
  left: auto !important;
}
.b-datepicker-container .wrap {
  padding-right: 0 !important;
  min-width: 235px;
  margin-right: -8px;
}
@media (max-width: 900px) {
  .sub_filters_right {
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-end !important;
  }
  .sub_filters_left {
    display: none !important;
  }
}

.sub_filters_right.move-rightside-tab {
  margin-left: 0 !important;
}
.bdc_top_band {
  padding: 0 !important;
  margin-top: -20px;
}
.exheader.sopened .extHeader .tfb-content div:first-child {
  margin-left: -9px;
}
.extHeader .filter-and-date-tabs {
  max-width: 1600px !important;
}
.sopened .extHeader .filter-and-date-tabs {
  padding: 0px 64px 0px 317px;
}
// .exheader.sopened .extHeader .tfb-content .text-right {
//   margin-right: -17px;
// }
// .exheader.sopened .extHeader .filter-and-date-tabs .sub_filters_left {
//   margin-left: -17px;
// }
.exheader.sopened .extHeader .filter-and-date-tabs .sub_filters_right {
  margin-right: -19px;
}
// .exheader .filter-and-date-tabs .sub_filters_left {
//   margin-left: -62px;
// }
// .exheader .filter-and-date-tabs .sub_filters_right {
//   margin-right: -64px;
// }
.exheader .tfb-content div:first-child {
  margin-left: -31px;
}
.exheader .tfb-content .text-right {
  margin-right: -61px;
}

.sclosed .tfb-content-wrap {
  padding-left: 89px !important;
  margin: auto;
  max-width: 1320px !important;
}

.sub_filters_right {
  display: flex;
  text-align: right;
  align-items: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  margin-top: -2px;

  &.move-rightside-tab {
    //margin-left: 383px;
    margin-left: 36.5%;
  }
}
.sub_filters_left {
  display: flex;
  align-items: center;
  margin-top: -2px;
}
::v-deep .sub_filters_container {
  .dropdown {
    margin: 0px 15px 0px 3px;
    border: 1px solid #c5d2d8;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    &:hover {
      border: 1px solid var(--primarycolor);
    }
    .droparw {
      color: #536176;
      // height: 27px;
      // line-height: 37px;
      float: right;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .select {
      height: 37px;
      padding: 3px 20px 3px 10px;
      min-height: 37px !important;
      display: flex;
      align-items: center;
    }
    .select.open {
      background-color: #fff;
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 5px 3px 1px rgba(111, 94, 94, 0.1);
      border-radius: 0 1px 4px 4px;
      border-right: 1px solid #c6d2d8;
      border-left: 1px solid #c6d2d8;
      border-bottom: 1px solid #c6d2d8;
      .norm-option-item {
        color: #536176;
      }
    }
    .selected-opt-wrapper {
      flex-direction: column;
      .selected-opt-header {
        background-color: transparent;
        filter: drop-shadow(0px 0px 5px #fff);
        top: -2px;
        font-size: 0.78em;
        padding: 0px 4px;
        margin-left: -4px;
        color: #969caf;
      }
      .selected-opt {
        color: var(--primarydark2);
        font-size: 14px;
        position: relative;
        text-transform: capitalize;
        bottom: 5px;
        margin-left: 0;
      }
    }
  }
}
.sub_filters_bar {
  position: fixed;
  z-index: 101;
  width: 100%;
  top: 67px;
  display: inline-flex;
  min-height: 56.5px;
  background-color: #f4f5fa;
  box-shadow: none;
  padding-left: 9.5%;
  transition: all 0.3s ease;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  padding-right: 5.5%;
  background-size: 6px 6px;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.04) 1px, rgba(0, 0, 0, 0) 1px);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fafbfd;
  .filter-and-date-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: all 0.3s ease;
  }
  .move-filter-tab {
    //margin-left: 162px;
    margin-left: 15.7%;
  }
}
// .sclosed .filter-and-date-tabs {
//   padding: 4px 35px 4px 30px;
// }
.bdc_top_band {
  // position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-left: 279px;
  background-color: rgb(250, 250, 251);
  z-index: 102;
  min-height: 55px;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  border-top: 3px solid var(--primarycolor);
}
table {
  font-weight: 300;
}
::v-deep .wrap:hover {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .drp-wrap .compare-label {
  font-family: 'Manrope', sans-serif !important;
}
::v-deep .drp-wrap {
  position: relative;
  display: block;
  width: 222px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: auto;
  color: #fff;
  border-bottom: 0px;
}
::v-deep .b-datepicker-container {
  bottom: 0 !important;
}
::v-deep .b-datepicker-container .drp-wrap > input {
  color: #595e6b;
  font-size: 1.13em;
  letter-spacing: 0.03em;
  width: 100%;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  box-shadow: none !important;
  padding: 0 !important;
  background: none !important;
  line-height: normal !important;
  height: auto !important;
  max-width: unset;
  margin-bottom: 0;
  min-width: 216px;
}
::v-deep .drp-wrap .compare-label {
  text-align: right;

  font-size: 75%;
  color: rgba(129, 140, 165, 1);
  cursor: pointer;
}

.bdc_top {
  margin-bottom: 30px;
}
.bdc_top_right {
  width: 49%;
  float: right;
  margin-top: -9px;
}
::v-deep .ad-group-select .dropdown-menu-multi-loading {
  min-height: 141px !important;
}
::v-deep .campaign-select .dropdown-menu-multi,
::v-deep .ad-group-select .dropdown-menu-multi,
::v-deep .pixel-select .dropdown-menu-multi {
  margin-top: 6px;
  border-radius: 4px;
  border-top: 1px solid #c6d2d8;
  width: auto !important;
  min-width: 160px !important;
  max-width: 330px !important;
}
::v-deep .campaign-select,
::v-deep .ad-group-select,
::v-deep .pixel-select {
  &.rmvBtmBorderRadius {
    border-radius: 6px !important;
  }
}
.disabled {
  pointer-events: none !important;
  opacity: 0.3;
  filter: brightness(0.9);
}
.inactive-filter-bar {
  .sub_filters_bar {
    pointer-events: none !important;
    opacity: 0.5;
  }
}

// Custom MNI styles here
.custom-bg-color {
  background-color: #696c7e;
  border-top: 3px solid #696c7e;
}
.custom-bg-title {
  color: #ffffff !important;
  font-size: 20px;
  font-family: 'Manrope', sans-serif !important;
}

.opt-b1 {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #c5d2d8;
  height: 40px;
  margin-top: 2px !important;
  min-width: 140px !important;
  margin-left: 4px;
}

.cnv-text {
  font-size: 14px;
  color: #707e8a;
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 5px;
  margin-top: -2px;
}

.dt-input {
  padding-left: 6px;
  font-size: 14px;
  max-width: 30px;
  top: 15px;
  left: 4px;
  background: transparent !important;
  border: transparent !important;
}
.mniAccountStyle {
  min-height: 63px;
  background-image: none !important; /* Override the background-image if isMniAccount is true */
  border-bottom: none;
}
.cstm-mni-filter {
  min-height: 80px;
}
.input-disabled {
  opacity: 0.5;
}
.events-dd {
  padding-top: 2px;
  max-width: 220px !important;
}
.events-dd .placements.dd {
  padding-top: 2px;
  max-width: 225px !important;
}
::v-deep .advance_filters_container {
  &.margin-left {
    margin-left: 12px;
  }
  width: 50%;
  .dropdown {
    border: 1px solid #c5d2d8;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    width: 273px;
    max-width: unset;
    &:hover {
      border: 1px solid var(--primarycolor);
    }
    .droparw {
      color: #536176;
      // height: 27px;
      // line-height: 37px;
      float: right;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .select {
      height: 37px;
      padding: 3px 20px 3px 10px;
      min-height: 37px !important;
      display: flex;
      align-items: center;
      border-radius: 6px 6px 0px 0px;
    }
    .select.open {
      background-color: #fff;
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 5px 3px 1px rgba(111, 94, 94, 0.1);
      border-radius: 0 1px 4px 4px;
      border-right: 1px solid #c6d2d8;
      border-left: 1px solid #c6d2d8;
      border-bottom: 1px solid #c6d2d8;
      .norm-option-item {
        color: #536176;
      }
    }
    .selected-opt-wrapper {
      flex-direction: column;
      .selected-opt-header {
        background-color: transparent;
        filter: drop-shadow(0px 0px 5px #fff);
        top: -2px;
        font-size: 0.78em;
        padding: 0px 4px;
        margin-left: -4px;
        color: #969caf;
      }
      .selected-opt {
        color: var(--primarydark2);
        font-size: 14px;
        position: relative;
        text-transform: capitalize;
        bottom: 5px;
        margin-left: 0;
      }
    }
  }
}
::v-deep .advance_filters_container .top-span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sopened .sub_filters_drop_wrap {
  padding-left: 280px;
}

::v-deep .advance_filters_container .sub-drop .fdrop-label {
  display: inline-block;
  font-size: 0.82em;
  font-weight: 500;
  position: absolute;
  top: -10px;
  background-color: #ffffff;
  padding: 0px 4px;
  margin-left: -4px;
  color: #969caf;
}
.search-checkbox-dropdown {
  max-height: 36px;
  min-width: 180px;
  margin: 2px;
  margin-right: 15px;
}
::v-deep .loading-dropdown .vs__selected-options {
  display: none;
}
::v-deep .loading-dropdown .vs__actions {
  width: 100%;
}

::v-deep .loading-dropdown .vs__spinner {
  margin: 0 auto;
  border-left-color: var(--primarycolor);
}

.ss-nav {
  display: inline-block;
  float: right;
  margin-top: 22px;

  .nav-item {
    display: inline-block;
    color: white;
    cursor: pointer;
  }
}
.group-nav {
  display: flex;
  gap: 10px;
  margin-right: -30px;
}
.nav-item:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);

  .ss-icon-txt {
    margin-top: 26px;
    opacity: 1;
    &.twolines {
      margin-top: 21px;
    }
  }
}
.ss-icon-txt {
  position: absolute;
  left: 50%;
  display: inline-block;
  width: 60px;
  margin-top: 10px;
  margin-left: -30px;
  font-size: 10px;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.hicon {
  position: relative;
  display: inline-block;
  width: 56px;
  padding: 20px 0px;
  margin: 0px;
  font-size: 18px;
  color: #a1a5af !important;
  text-align: center;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  &:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  &.active {
    color: #fff !important;
  }
  &:hover {
    top: -6px;
    max-height: 66px;
    color: #fff;
  }
}
.hicon.active::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--primarycolor);
}
.mp-nav-acct-name {
  font-size: 12px;
  font-weight: 400;
  color: #a1a5af;
  cursor: pointer;
  margin-top: 25px;
  // margin-left: -130px;
  padding-left: 10px;
  .navpencil {
    height: 60px;
    margin-top: -5px;
    font-size: 19px;
    color: #797c8a;
    cursor: pointer;
    width: 0.875em;
  }
}
.account-with-icon {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
}
</style>
