<template>
  <div class="rpt_main_wrap">
    <div class="rpt_panel def-box expan">
      <div class="tabs-container">
        <!-- Tabs Navigation -->
        <div class="tabs">
          <div
            v-for="(tab, index) in tabs"
            :id="'tab-' + index + '-' + tab.name.replace(/\s+/g, '')"
            :key="index"
            class="tab"
            :class="{
              active: isActiveTab(index),
              disabled:
                isTabDisabled(index) ||
                showSpinnerWhileSavingTemplate ||
                showSpinnerWhileEmailing ||
                isDataLoading,
            }"
            @click="handleTabClick(index)"
          >
            {{ tab.name }}
            <div v-if="isActiveTab(index)" class="active-line"></div>
          </div>
          <div v-if="isActiveTab(2)" class="flp-controls">
            <div class="fright" :class="{ disabled: isViewReportLoading }">
              <b-save-template-btn
                v-if="isVisible"
                :external-open="openSaveTemplatePopup"
                :is-loading="showSpinnerWhileSavingTemplate"
                @save-template="saveTemplate"
              />
              <k-download-btn
                :preparing="downloadBtn.preparing"
                :done="downloadBtn.done"
                @click.native="download()"
                @click.stop
              />
              <b-email-btn
                :email-status="emailSentStatus"
                :external-open="openEmailPopup"
                :show-fill-with-user-email="true"
                :is-loading="showSpinnerWhileEmailing"
                :is-new-report-builder="true"
                @email-to="emailReport"
              />
            </div>
          </div>
        </div>
        <!-- Tabs Content -->
        <div class="tab-content">
          <component
            :is="currentComponent"
            ref="viewReportComponent"
            :active-solution-id="activeSolutionId"
            :template-name="templateName"
            :touch-reports-email-status="touchReportEmailStatus"
            @update-download-status="updateDownloadStatus"
            @update-email-sent-status="updateEmailSentStatus"
            @update-open-email-popup="updateOpenEmailPopup"
            @update-view-report-loading="updateViewReportLoading"
            @update-save-template-visibility="updateSaveTemplateVisibility"
            @show-spinner-while-saving-template="updateShowSpinnerWhileSavingTemplate"
            @show-spinner-while-emailing="updateShowSpinnerWhileEmailing"
            @update-template-name="updateTemplateNameVal"
            @export-n-send-touch-reports="triggerEmailForAllTouch"
            @export-n-send-all-ep-reports="triggerEmailForAllEp"
            @update-data-loading="updateDataLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import authzMixin from '@/components/mixins/authz-mixin';
import config from '@/config';
import { isBlank } from 'adready-api/helpers/common';
import { formatDateForAPI } from '@/util/apiDateFormat';
import advertiserReportsApi from '@/api/advertiser-reports';
import { buildQueryString } from '@/helpers/global/url-helpers';
import { ACCOUNTS_TO_SHOW, DEFAULT_METHODOLOGY, PRE_DEFINED_TEMPLATES } from '@/constant';
import ViewReport from './view-report.vue';
import MyReports from './my-reports.vue';
import ReportsBuilder from './reports-builder.vue';

export default {
  name: 'ReportsBuilder',
  components: {
    'reports-builder': ReportsBuilder,
    'my-reports': MyReports,
    'view-report': ViewReport,
    KDownloadBtn: () =>
      import(/* webpackChunkName: "k-download-btn" */ '~/components/elements/k-download-btn.vue'),
    BEmailBtn: () =>
      import(/* webpackChunkName: "b-email-btn" */ '~/components/elements/b-email-btn.vue'),
    BSaveTemplateBtn: () =>
      import(/* webpackChunkName: "b-email-btn" */ '~/components/elements/b-save-template-btn.vue'),
  },
  mixins: [authzMixin],
  props: {
    activeSolutionId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Report Builder', component: 'reports-builder' },
        { name: 'My Reports', component: 'my-reports' },
        { name: 'View Report', component: 'view-report' },
      ],
      activeTab: 1, // Default active tab index - My Reports
      disabledTabs: [2], // Initially disable tab at index 2
      downloadBtn: {
        preparing: false,
        done: false,
      },
      openEmailPopup: false,
      emailSentStatus: '',

      isViewReportLoading: true,
      templateName: '',
      isDataLoading: false,

      openSaveTemplatePopup: true,
      isVisible: false,
      showSpinnerWhileSavingTemplate: false,
      showSpinnerWhileEmailing: false,
      isAIAssistantInjected: false,
      reportKind: '',
      touchReportEmailStatus: {
        done: false,
        status: '',
        emails: '',
      },
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    universalPixelId: get('common/universalPixelId'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    attribution: get('dashboard/filters@attribution'),
    currentComponent() {
      return this.tabs[this.activeTab].component;
    },
    isActiveTab() {
      return (index) => this.activeTab === index;
    },
    selectedEventLabel() {
      return isBlank(this.selectedEvent.eventLabel) ? 'Event' : this.selectedEvent.eventLabel;
    },
  },
  mounted() {
    // delete initial storage values
    localStorage.removeItem('initialReportBuilderFilters');
  },
  created() {
    EventBus.$on('switch-to-tab-index', this.handleTabSwitchEvent);

    if (
      !this.hasAIAssistantFullPlatformAccess &&
      this.hasAIAssistantReportBuilderAccess &&
      !this.isAIAssistantInjected
    ) {
      this.injectAssistantScripts();
      this.isAIAssistantInjected = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('switch-to-tab-index', this.handleTabSwitchEvent);

    if (this.isAIAssistantInjected && !this.hasAIAssistantFullPlatformAccess) {
      this.uninjectAssistantScripts();
      this.isAIAssistantInjected = false;
    }
  },
  methods: {
    injectAssistantScripts() {
      const scripts = [
        {
          src: config.getAIAssistantReportBuilderScriptURL(),
          id: 'report-builder-assistant-script',
        },
        {
          src: config.getMNILogoScript(),
          id: 'mni-logo-script',
        },
      ];

      scripts.forEach(({ src, id }) => {
        if (!document.getElementById(id)) {
          // Prevent duplicate script injection
          const script = document.createElement('script');
          script.src = src;
          script.id = id;
          script.async = true;
          document.body.appendChild(script);
        }
      });
    },
    uninjectAssistantScripts() {
      this.$nextTick(() => {
        localStorage.setItem('hardReload', true);
      });
      const scriptIds = ['report-builder-assistant-script', 'mni-logo-script'];

      scriptIds.forEach((id) => {
        const script = document.getElementById(id);
        if (script) {
          script.remove();
        }
      });

      document.querySelectorAll('script').forEach((script) => {
        if (script.src.includes('ai/assistant/v2')) {
          script.remove();
        }
      });
    },
    handleTabClick(index) {
      if (!this.isTabDisabled(index)) {
        this.setActiveTab(index);
        this.isViewReportLoading = true;
      }
      if (index === 1) {
        // delete initial storage values
        localStorage.removeItem('initialReportBuilderFilters');
      }
      this.touchReportEmailStatus.done = false;
      this.touchReportEmailStatus.status = '';
      this.touchReportEmailStatus.emails = '';
    },
    setActiveTab(index) {
      this.activeTab = index;
      if (index !== 2 && !this.disabledTabs.includes(2)) {
        this.disabledTabs.push(2);
      }
    },
    handleTabSwitchEvent(index, name = '') {
      this.setActiveTab(index);
      this.enableTab(index);
      this.templateName = name;
    },
    isTabDisabled(index) {
      return this.disabledTabs.includes(index);
    },
    enableTab(index) {
      const tabIndex = this.disabledTabs.indexOf(index);
      if (tabIndex !== -1) {
        this.disabledTabs.splice(tabIndex, 1);
      }
    },
    download() {
      this.downloadBtn.preparing = true;
      if (this.currentComponent === 'view-report') {
        this.$refs.viewReportComponent.download(); // Call method in the View-report-component
      }
    },
    updateDownloadStatus(status) {
      this.downloadBtn.done = status.done;
      setTimeout(() => {
        this.downloadBtn.preparing = status.preparing;
        this.downloadBtn.done = status.done;
      }, 1000);
    },
    saveTemplate(templateName = '') {
      setTimeout(() => {
        this.openSaveTemplatePopup = false;
      }, 100);
      if (this.currentComponent === 'view-report') {
        this.$refs.viewReportComponent.saveTemplate(templateName); // Call method in the View-report-component
        this.openSaveTemplatePopup = true;
      }
    },
    updateTemplateNameVal(val) {
      this.templateName = val;
    },
    emailReport(email = '') {
      setTimeout(() => {
        this.openEmailPopup = false;
      }, 500);
      if (this.currentComponent === 'view-report') {
        this.$refs.viewReportComponent.emailReport(email); // Call method in the View-report-component
      }
    },
    async triggerEmailForAllEp(load) {
      this.touchReportEmailStatus.done = false;
      this.touchReportEmailStatus.status = 'running';
      this.touchReportEmailStatus.emails = '';

      const payLoad = this.preparePayload(load);
      const selectedReportKind =
        load?.filters?.template?.toLowerCase() || load?.filters?.dimensions[0]?.toLowerCase() || '';
      try {
        const filters = {
          ...payLoad,
          event: 'Leads',
          category: 'lead',
          kind: selectedReportKind,
          emailAddr: load.emailList,
          onceNow: true,
          emailIt: true,
        };
        const opts = {
          headers: {
            Accept: 'application/json',
          },
          respondHeaders: true,
        };
        let res = {};
        res = await advertiserReportsApi.reportsEventPerformance(
          this.advertiser.id,
          buildQueryString(filters),
          opts
        );
        if (res.status === 200) {
          this.touchReportEmailStatus.done = true;
          this.touchReportEmailStatus.status = 'success';
          this.touchReportEmailStatus.emails = load.emailList;
        }
      } catch (err) {
        const errorMessage =
          err.response?.message ||
          'Due to internal error, email has not been sent now. Please try later!';
        this.touchReportEmailStatus.done = true;
        this.touchReportEmailStatus.status = errorMessage;
      } finally {
        this.touchReportEmailStatus.done = true;
      }
    },
    async triggerEmailForAllTouch(load) {
      this.touchReportEmailStatus.done = false;
      this.touchReportEmailStatus.status = 'running';
      this.touchReportEmailStatus.emails = '';

      const payLoad = this.preparePayload(load);
      this.reportKind = load.template;

      const payload = { ...payLoad, emailAddr: load.emailList, onceNow: true, emailIt: true };
      try {
        let selectedReportType = '';
        if (PRE_DEFINED_TEMPLATES.includes(this.reportKind)) {
          selectedReportType = 'rollup';
        }

        if (selectedReportType === 'rollup') {
          delete payload.interval;
          delete payload.showUniques;
          delete payload.attribution;
          delete payload.methodology;
          payload.kind = this.reportKind;
          payload.includeChannel = ACCOUNTS_TO_SHOW.includes(this.account?.id);

          if (this.selectedEvent.allSubCategoriesSelected) {
            delete payload.subCategory;
          }

          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };

          let res = {};
          res = await advertiserReportsApi.reportsRollup(
            this.advertiser.id,
            buildQueryString(payload),
            opts
          );
          if (res.status === 200) {
            this.touchReportEmailStatus.done = true;
            this.touchReportEmailStatus.status = 'success';
            this.touchReportEmailStatus.emails = payload.emailAddr;
          }
        }
      } catch (err) {
        const errorMessage =
          err.response?.message ||
          'Due to internal error, email has not been sent now. Please try later!';
        this.touchReportEmailStatus.done = true;
        this.touchReportEmailStatus.status = errorMessage;
      } finally {
        this.touchReportEmailStatus.done = true;
      }
    },
    preparePayload(payload) {
      return {
        advertiser: this.advertiser?.name || '',
        client: this.account?.name || '',
        xandrId: this.advertiser?.xandrAdvertiserId || '',
        pixel: this.universalPixelId,
        mediaType: payload?.filters?.mediaTypes,
        ioIds: payload?.filters?.campaignIds,
        startDate: formatDateForAPI(payload?.filters?.startDate),
        endDate: formatDateForAPI(payload?.filters?.endDate),
        adGroups: payload?.filters?.adGroups || '',
        audience: payload?.filters?.audiences,
        publisher: payload?.filters?.publisher,
        creative: payload?.filters?.creatives,
        conversionWindow:
          payload?.filters?.conversionWindowUnit === 'DAYS'
            ? payload?.filters?.conversionWindow * 24
            : payload?.filters?.conversionWindow || 744,
        showUniques: payload?.filters?.showUniques,
        methodology: payload?.filters?.methodology?.toLowerCase() || DEFAULT_METHODOLOGY,
        attribution: this.attribution,
        event: this.selectedEventLabel,
        category: isBlank(this.selectedEvent.category) ? 'none' : this.selectedEvent.category,
        subCategory: this.selectedEvent.subCategories,
        interval: payload?.filters?.interval || 'CUMULATIVE',
        reportBuilderV2: true,
        templateName: payload?.name ?? '',
        dateRangeType: payload?.filters?.dateRangeType ?? '',
      };
    },
    updateOpenEmailPopup(status) {
      this.openEmailPopup = status.openEmailPopup;
    },
    updateEmailSentStatus(status) {
      this.emailSentStatus = status.emailSentStatus;
    },
    updateViewReportLoading(loading) {
      this.isViewReportLoading = loading.isLoading;
    },
    updateSaveTemplateVisibility(status) {
      this.isVisible = status.status;
    },
    updateShowSpinnerWhileSavingTemplate(status) {
      this.showSpinnerWhileSavingTemplate = status.status;
    },
    updateShowSpinnerWhileEmailing(status) {
      this.showSpinnerWhileEmailing = status.status;
    },
    updateDataLoading(isLoading) {
      this.isDataLoading = isLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.rpt_main_wrap {
  transition: all 0.3s ease;
  margin-top: -40px;
}

.rpt_panel {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 40, 60, 0.07), 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgba(165, 173, 178, 0.22);
}

@media (max-width: 900px) {
  .rpt_panel.two-thirds,
  .rpt_panel.one-thirds {
    width: 100% !important;
  }
}

.rpt_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}

.rpt_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.rpt_panel.last {
  float: right;
}

.rpt_panel .dropdown {
  border: 1px solid #c8d3e4;
}

.def-box {
  padding: 10px;
  max-height: 1070px;
  z-index: 10;
  position: relative;
  min-height: 200px;
}

.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}

.tabs-container {
  font-family: 'Source Sans Pro', sans-serif;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  color: #555;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease;
}

.tab:hover {
  color: #333;
}

.tab.active {
  color: #000;
  font-weight: bold;
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primarycolor);
}

.tab.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.fright {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flp-controls {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
}

::v-deep .btn-size2 {
  padding: 10px;
}

::v-deep .k-btn.btn-size-4 {
  background-color: var(--primarycolor) !important;
  color: var(--buttontext) !important;
}
</style>
