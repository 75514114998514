<template>
  <div class="grid-item delivery">
    <div class="section">
      <div ref="deliveryVals" :class="{ 'error-highlight': localShowErrorOnDeliveryVals }">
        <div class="filter-ttl"><span>DELIVERY</span></div>
        <div class="filter checkbox-select">
          <div
            ref="runNowOpts"
            class="checkbox-select__check-wrapp rounded-input form-group"
            :class="{ 'error-highlight': localShowErrorOnRunNowOpts }"
          >
            <input id="runNow" v-model="localRunNow" type="checkbox" @change="runNowChanged" />
            <label for="runNow">Run now, show results here</label>
          </div>
          <div class="checkbox-select__check-wrapp rounded-input form-group">
            <input
              id="exportAndEmailReport"
              v-model="localExportNSendReport"
              type="checkbox"
              @change="exportNSendReportChanged"
            />
            <label for="exportAndEmailReport">Export, send results via email</label>
          </div>
          <div v-if="localExportNSendReport">
            <div ref="emailToList" class="schedule-pad">
              <div :class="{ 'error-highlight': localShowErrorOnEmailTo }">
                <span>Email To:</span>
                <div class="email-container" @click="focusEmailInput">
                  <div
                    v-for="(email, index) in emailsToSend"
                    :key="index"
                    class="email-chip"
                    :title="email"
                  >
                    {{ email }}
                    <span class="remove" @click="removeEmailToSend(index)">&times;</span>
                  </div>
                  <textarea
                    ref="textareaemails"
                    v-model="inputEmail"
                    placeholder="Type emails..."
                    class="email-input"
                    rows="1"
                    @keydown.enter.prevent="addEmailToSend"
                    @keydown.space.prevent="addEmailToSend"
                    @keydown.backspace="handleBackspaceEmailToSend"
                    @keydown="handleKeydownEmailToSend"
                    @input="adjustEmailToSendHeight"
                    @focus="addClickEventListenerEmailToSend"
                    @blur="removedFocusEmailToSend"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="checkbox-line">
            <div
              class="checkbox-select__check-wrapp form-group"
              :class="{ disabled: disableSaveReportTemplate || localDisableScheduleReport }"
            >
              <input
                id="scheduleReport"
                v-model="localScheduleReport"
                type="checkbox"
                @change="scheduleReportChanged"
              />
              <label for="scheduleReport">Schedule Report</label>
            </div>
            <div v-if="localDisableScheduleReport" class="tooltip-container">
              <font-awesome-icon
                id="reportNameTooltip"
                class="tooltip-icon"
                icon="fas fa-info-circle"
                style="right: 10px; cursor: pointer;"
                @mouseenter="showSchedTooltip = true"
                @mouseleave="showSchedTooltip = false"
              />
              <div v-if="showSchedTooltip" class="custom-sched-tooltip">
                Scheduling report with a custom date range is not allowed.
              </div>
            </div>
          </div>
          <div v-if="localScheduleReport">
            <div ref="schedulerList" class="schedule-pad">
              <div :class="{ 'error-highlight': localShowErrorOnSchedule }">
                <span>When:</span>
                <div class="schedule-when">
                  <b-select
                    class="frequency-select"
                    :options="frequencyOptions"
                    :selected="frequency"
                    @change="onFrequencyChange"
                  />
                </div>
                <div class="date-container">
                  <div class="date-field">
                    <span>Start Date:</span>
                    <div class="input-icon-wrapper">
                      <input
                        ref="startDateInput"
                        v-model="scheduleStartDate"
                        type="date"
                        class="date-input"
                        :min="currentDate"
                        :max="maxDate"
                        @change="handleStartDateChange"
                        @blur="validateStartDate"
                        @focus="setCurrentValueStartDate"
                      />
                      <font-awesome-icon
                        :icon="['far', 'fa-calendar-alt']"
                        class="calendar-icon"
                        @click="$refs.startDateInput.showPicker()"
                      />
                    </div>
                  </div>

                  <div class="date-field">
                    <span>End Date:</span>
                    <div class="input-icon-wrapper">
                      <input
                        ref="endDateInput"
                        v-model="scheduleEndDate"
                        type="date"
                        class="date-input"
                        :min="currentDate"
                        @change="handleEndDateChange"
                        @blur="validateEndDate"
                        @focus="setCurrentValueEndDate"
                      />
                      <font-awesome-icon
                        :icon="['far', 'fa-calendar-alt']"
                        class="calendar-icon"
                        @click="$refs.endDateInput.showPicker()"
                      />
                    </div>
                  </div>
                </div>
                <span>Email To:</span>
                <div class="email-container" @click="focusInput">
                  <div
                    v-for="(email, index) in emails"
                    :key="index"
                    class="email-chip"
                    :title="email"
                  >
                    {{ email }}
                    <span class="remove" @click="removeEmail(index)">&times;</span>
                  </div>
                  <textarea
                    ref="textareasched"
                    v-model="input"
                    placeholder="Type emails..."
                    class="email-input"
                    rows="1"
                    @keydown.enter.prevent="addEmail"
                    @keydown.space.prevent="addEmail"
                    @keydown.backspace="handleBackspace"
                    @keydown="handleKeydown"
                    @input="adjustHeight"
                    @focus="addClickEventListener"
                    @blur="removedFocus"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div
            class="checkbox-select__check-wrapp form-group"
            :class="{
              disabled: disableSaveReportTemplate || localDisableSaveReportBasedOnSchedReport,
            }"
          >
            <input
              id="saveTemplate"
              v-model="localSaveTemplate"
              type="checkbox"
              @change="saveTemplateChanged"
            />
            <label for="saveTemplate">Save as report template</label>
          </div>
          <div
            v-if="localSaveTemplate"
            ref="templateNameVal"
            style="padding-left:30px;"
            :class="{ 'error-highlight': localShowErrorOnTemplateName }"
          >
            <label for="reportName">Name this report</label>
            <div class="input-wrapper">
              <textarea
                id="reportName"
                ref="textarea"
                v-model="localReportName"
                :aria-describedby="isValidReportName ? '' : 'reportNameTooltip'"
                :maxlength="100"
                class="report-name-input"
                rows="1"
                @input="handleInput"
                @keypress="validateInput"
                @paste="handlePaste"
              ></textarea>
              <div class="tooltip-container">
                <font-awesome-icon
                  id="reportNameTooltip"
                  class="tooltip-icon"
                  icon="fas fa-info-circle"
                  style="position: absolute; right: 10px; cursor: pointer;"
                  @mouseenter="showTooltip = true"
                  @mouseleave="showTooltip = false"
                />
                <div v-if="showTooltip" class="custom-tooltip">
                  Only alphanumeric characters, dashes and underscores are allowed.
                </div>
              </div>
            </div>
            <div class="remaining-characters">{{ remainingCharactersText }}</div>
          </div>
        </div>
      </div>
      <button
        id="run_report_btn"
        class="flp-btn btn-size2"
        :class="{ 'disabled-button': disabledRunBtn }"
        style=" z-index:10;padding-left: 17px; margin: 0px; margin-top: 15px; width: 185px !important"
        :disabled="disabledRunBtn"
        @click="runOrSaveReport"
      >
        <span>{{ buttonLabel }}</span>
        <span class="flp-btn-icon-size2">
          <font-awesome-icon icon="fal fa-arrow-right" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import BSelect from '@/components/elements/b-select.vue';

export default {
  components: {
    BSelect,
  },
  props: {
    runNow: { type: Boolean, required: true },
    saveTemplate: { type: Boolean, required: true },
    scheduleReport: { type: Boolean, required: true },
    exportNSendReport: { type: Boolean, required: true },
    frequencyVal: { type: String, required: true },
    schedStartDate: { type: String, required: true },
    schedEndDate: { type: String, required: true },
    emailToVal: { type: Array, required: true },
    emailToSend: { type: Array, required: true },
    reportName: { type: String, required: true },
    buttonLabel: { type: String, required: true },
    isValidReportName: { type: Boolean, required: true },
    disableSaveReportTemplate: { type: Boolean, required: true },
    disableScheduleReport: { type: Boolean, required: true },
    disableSaveReportBasedOnSchedReport: { type: Boolean, required: false },
    disabledRunBtn: { type: Boolean, default: false },
    showErrorOnTemplateName: { type: Boolean, default: false },
    showErrorOnEmailTo: { type: Boolean, default: false },
    showErrorOnSchedule: { type: Boolean, default: false },
    showErrorOnDeliveryVals: { type: Boolean, default: false },
    showErrorOnRunNowOpts: { type: Boolean, default: false },
  },
  data() {
    return {
      localRunNow: this.runNow,
      localReportName: this.reportName,
      localSaveTemplate: this.saveTemplate,
      localDisableSaveReportBasedOnSchedReport: this.disableSaveReportBasedOnSchedReport,
      showTooltip: false,
      showSchedTooltip: false,
      localShowErrorOnTemplateName: this.showErrorOnTemplateName,
      localShowErrorOnEmailTo: this.showErrorOnEmailTo,
      localShowErrorOnSchedule: this.showErrorOnSchedule,
      localShowErrorOnDeliveryVals: this.showErrorOnDeliveryVals,
      localShowErrorOnRunNowOpts: this.showErrorOnRunNowOpts,
      localDisableScheduleReport: this.disableScheduleReport,

      frequencyOptions: [
        {
          id: 'select',
          name: 'Select Frequency',
        },
        {
          id: 'WEEKLY',
          name: 'Weekly',
        },
        {
          id: 'BI_WEEKLY',
          name: 'Bi-Weekly',
        },
        {
          id: 'MONTHLY',
          name: 'Monthly',
        },
        {
          id: 'MONTH_END',
          name: 'End of Month',
        },
      ],
      frequency: 'select',

      localScheduleReport: this.scheduleReport,
      localExportNSendReport: this.exportNSendReport,
      emails: [],
      emailsToSend: [],
      input: '',
      inputEmail: '',
      scheduleStartDate: '',
      currentValStartDate: '',
      scheduleEndDate: '',
      currentValEndDate: '',
      currentDate: this.getTomorrowDate(), // Set min date to today
      maxDate: this.formatDate(this.addYears(new Date(), 1)), // Max date (1 year from today)
      isListenerAdded: false,
      isListenerAddedEmailToSend: false,
      hasFocusOn: false,
      hasFocusOnEmailToSend: false,
    };
  },
  computed: {
    remainingCharactersText() {
      const remaining = 100 - this.localReportName.length;
      return `${remaining} character${remaining < 2 ? '' : 's'} remaining`;
    },
  },
  watch: {
    reportName(newVal) {
      this.localReportName = newVal;
    },
    saveTemplate(newVal) {
      this.localSaveTemplate = newVal;
    },
    scheduleReport(newVal) {
      this.localScheduleReport = newVal;
      if (!newVal) {
        this.resetScheduleReport();
      } else {
        this.localSaveTemplate = true;
        this.$emit('save-template-changed', this.localSaveTemplate);
      }
    },
    exportNSendReport(newVal) {
      this.localExportNSendReport = newVal;
      if (!newVal) {
        this.resetExportNSendReport();
      } else {
        this.localRunNow = false;
      }
    },
    runNow(newVal) {
      this.localRunNow = newVal;
      if (newVal) {
        this.localExportNSendReport = false;
        this.resetExportNSendReport();
      }
    },
    disableSaveReportTemplate(newVal) {
      if (newVal && this.localSaveTemplate) {
        this.localSaveTemplate = false;
        this.$emit('save-template-changed', this.localSaveTemplate);
      }
    },
    disableScheduleReport(newVal) {
      this.localDisableScheduleReport = newVal;
    },
    disableSaveReportBasedOnSchedReport(val) {
      this.localDisableSaveReportBasedOnSchedReport = val;
    },
    showErrorOnTemplateName(val) {
      this.localShowErrorOnTemplateName = val;
    },
    showErrorOnDeliveryVals(val) {
      this.localShowErrorOnDeliveryVals = val;
    },
    showErrorOnRunNowOpts(val) {
      this.localShowErrorOnRunNowOpts = val;
    },
    showErrorOnEmailTo(val) {
      this.localShowErrorOnEmailTo = val;
    },
    showErrorOnSchedule(val) {
      this.localShowErrorOnSchedule = val;
    },
    localReportName() {
      this.$nextTick(() => {
        this.adjustHeight();
      });
    },
    frequencyVal(newVal) {
      this.frequency = newVal;
    },
    schedStartDate(newVal) {
      this.scheduleStartDate = newVal;
    },
    schedEndDate(newVal) {
      this.scheduleEndDate = newVal;
    },
    emailToVal(newVal) {
      this.emails = newVal;
    },
    emailToSend(newVal) {
      this.emailsToSend = newVal;
    },
  },
  methods: {
    getTomorrowDate() {
      const today = new Date().toISOString().split('T')[0];

      const todayDateObj = new Date(today);
      const tomorrowDateObj = new Date(todayDateObj);
      tomorrowDateObj.setDate(todayDateObj.getDate() + 1);
      return tomorrowDateObj.toISOString().split('T')[0];
    },
    resetScheduleReport() {
      this.emails = [];
      this.input = '';
      this.scheduleStartDate = '';
      this.scheduleEndDate = '';
      this.frequency = 'select';
      this.$emit('emails-changed', this.emails);
      this.$emit('schedule-end-date-changed', this.scheduleEndDate);
      this.$emit('on-frequency-change', this.frequency);
      this.$emit('schedule-start-date-changed', this.scheduleStartDate);
    },
    resetExportNSendReport() {
      this.emailsToSend = [];
      this.inputEmail = '';
      this.$emit('emails-to-send-changed', this.emailsToSend);
    },
    addClickEventListener() {
      this.hasFocusOn = true;
      if (!this.isListenerAdded) {
        document.addEventListener('click', this.handleClickOutside);
        this.isListenerAdded = true;
      }
    },
    addClickEventListenerEmailToSend() {
      this.hasFocusOnEmailToSend = true;
      if (!this.isListenerAddedEmailToSend) {
        document.addEventListener('click', this.handleClickOutsideEmailToSend);
        this.isListenerAddedEmailToSend = true;
      }
    },
    removedFocus() {
      this.hasFocusOn = false;
    },
    removedFocusEmailToSend() {
      this.hasFocusOnEmailToSend = false;
    },
    handleClickOutside(event) {
      const textarea = this.$refs.textareasched;
      if (!textarea?.contains(event.target)) {
        this.addEmail();
        this.adjustHeightSched();
        if (this.isListenerAdded && !this.hasFocusOn) {
          document.removeEventListener('click', this.handleClickOutside);
          this.isListenerAdded = false;
        }
      }
    },
    handleClickOutsideEmailToSend(event) {
      const textarea = this.$refs.textareaemails;
      if (!textarea?.contains(event.target)) {
        this.addEmailToSend();
        this.adjustEmailToSendHeight();
        if (this.isListenerAddedEmailToSend && !this.hasFocusOnEmailToSend) {
          document.removeEventListener('click', this.handleClickOutsideEmailToSend);
          this.isListenerAddedEmailToSend = false;
        }
      }
    },
    runNowChanged() {
      this.$emit('run-now-changed', this.localRunNow);
      if (this.localRunNow) {
        this.localExportNSendReport = false;
        this.$emit('export-n-send-report-changed', this.localExportNSendReport);
      }
    },
    saveTemplateChanged() {
      this.$nextTick(() => {
        this.adjustHeight();
      });
      this.$emit('save-template-changed', this.localSaveTemplate);
    },
    scheduleReportChanged() {
      this.$emit('schedule-report-changed', this.localScheduleReport);
    },
    exportNSendReportChanged() {
      this.$emit('export-n-send-report-changed', this.localExportNSendReport);
      if (this.localExportNSendReport) {
        this.localRunNow = false;
        this.$emit('run-now-changed', this.localRunNow);
      }
    },
    onFrequencyChange(frequency) {
      this.$emit('on-frequency-change', frequency);
    },
    runOrSaveReport() {
      this.$emit('run-or-save-report');
    },
    handleInput(event) {
      this.localReportName = event.target.value;
      this.$emit('report-name-changed', this.localReportName);
      this.adjustHeight(event);
    },
    adjustHeight(event) {
      const textarea = event ? event.target : this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    adjustEmailToSendHeight(event) {
      const textarea = event ? event.target : this.$refs.textareaemails;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    validateInput(event) {
      const regex = /^[a-zA-Z0-9-_ ]*$/;
      if (!regex.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
    handlePaste(event) {
      event.preventDefault(); // Prevent the default paste action

      // Get the pasted text
      const pasteText = event.clipboardData.getData('text');

      // Sanitize the pasted text
      const sanitizedText = pasteText.replace(/[^a-zA-Z0-9-_ ]/g, '');

      // Get the current cursor position
      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;

      // Calculate the maximum length for the new value
      const maxLength = 100;
      const currentValue = this.localReportName;
      const textBeforeCursor = currentValue.slice(0, start);
      const textAfterCursor = currentValue.slice(end);

      // Create the new value ensuring it respects max length
      const newValue = textBeforeCursor + sanitizedText + textAfterCursor;
      const truncatedValue = newValue.slice(0, maxLength);

      // Update the local report name with the truncated value
      this.localReportName = truncatedValue;

      // Emit the change
      this.$emit('report-name-changed', truncatedValue);

      // Adjust the height of the textarea
      this.adjustHeight(event);
    },
    addEmailToSend() {
      const trimmed = this.inputEmail.trim();
      if (!trimmed || this.emailsToSend.includes(trimmed)) {
        this.inputEmail = '';
        return;
      }
      if (this.validateEmail(trimmed)) {
        this.emailsToSend.push(trimmed);
        this.inputEmail = '';
        this.$emit('emails-to-send-changed', this.emailsToSend);
        this.adjustEmailToSendHeight();
      }
    },
    addEmail() {
      const trimmed = this.input.trim();
      if (!trimmed || this.emails.includes(trimmed)) {
        this.input = '';
        return;
      }
      if (this.validateEmail(trimmed)) {
        this.emails.push(trimmed);
        this.input = '';
        this.$emit('emails-changed', this.emails);
        this.adjustHeight();
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
      this.adjustHeightSched();
      this.$emit('emails-changed', this.emails);
    },
    removeEmailToSend(index) {
      this.emailsToSend.splice(index, 1);
      this.adjustEmailToSendHeight();
      this.$emit('emails-to-send-changed', this.emailsToSend);
    },
    handleBackspace() {
      if (this.input === '' && this.emails.length) {
        this.emails.pop();
        this.adjustHeightSched();
      }
    },
    handleBackspaceEmailToSend() {
      if (this.inputEmail === '' && this.emailsToSend.length) {
        this.emailsToSend.pop();
        this.adjustEmailToSendHeight();
      }
    },
    handleKeydown(event) {
      if (event.key === ',') {
        event.preventDefault();
        this.addEmail();
        this.adjustHeightSched();
      }
    },
    handleKeydownEmailToSend(event) {
      if (event.key === ',') {
        event.preventDefault();
        this.addEmailToSend();
        this.adjustEmailToSendHeight();
      }
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    focusInput() {
      this.$refs.textareasched.focus();
    },
    focusEmailInput() {
      this.$refs.textareaemails.focus();
    },
    adjustHeightSched() {
      if (this.$refs.textareasched) {
        this.$nextTick(() => {
          this.$refs.textareasched.style.height = 'auto';
          this.$refs.textareasched.style.height = `${this.$refs.textareasched.scrollHeight}px`;
        });
      }
    },
    openDatePicker(refName) {
      const input = this.$refs[refName];
      if (input) {
        input.focus(); // Focuses the input field
        setTimeout(() => input.click(), 50); // Triggers the date picker after focusing
      }
    },
    formatDate(date) {
      return date.toISOString().split('T')[0]; // YYYY-MM-DD
    },
    addYears(date, years) {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() + years);
      return newDate;
    },
    preventTyping(event) {
      // Prevents typing but allows clicking the date picker
      event.target.blur();
      setTimeout(() => event.target.focus(), 0); // Re-focus for the calendar popup
    },
    handleStartDateChange(event) {
      let schedStartDt = event.target.value;
      if (!schedStartDt) {
        this.$emit('schedule-start-date-changed', '');
        return; // Stop further validation if empty
      }

      const selectedDate = new Date(schedStartDt);
      const now = new Date();
      now.setHours(0, 0, 0, 0); // compare only the date part

      // check if the date is a valid date format
      if (Number.isNaN(selectedDate)) {
        return;
      }

      // Min Date Validation (if currentDate is provided)
      if (this.currentDate) {
        const minDate = new Date(this.currentDate);
        minDate.setHours(0, 0, 0, 0);
        if (selectedDate < minDate) {
          return;
        }
      }

      // Max Date Validation (if maxDate is provided)
      if (this.maxDate) {
        const maxDateValue = new Date(this.maxDate);
        maxDateValue.setHours(0, 0, 0, 0);
        if (selectedDate > maxDateValue) {
          return;
        }
      }

      if (this.scheduleEndDate !== '' && this.scheduleEndDate < schedStartDt) {
        schedStartDt = this.scheduleEndDate;
        this.scheduleStartDate = this.scheduleEndDate;
      }
      this.$emit('schedule-start-date-changed', schedStartDt);
    },
    setCurrentValueStartDate() {
      this.currentValStartDate = this.scheduleStartDate;
    },
    validateStartDate() {
      if (this.currentValStartDate === this.scheduleStartDate) {
        return;
      }

      if (!this.scheduleStartDate) {
        this.scheduleStartDate = '';
        return; // Stop further validation if empty
      }

      const selectedDate = new Date(this.scheduleStartDate);

      // check if the date is a valid date format
      if (Number.isNaN(selectedDate)) {
        this.scheduleStartDate = this.currentDate;
      }

      // Min Date Validation (if currentDate is provided)
      if (this.currentDate) {
        const minDate = new Date(this.currentDate);
        minDate.setHours(0, 0, 0, 0);
        if (selectedDate < minDate) {
          this.scheduleStartDate = this.currentDate;
        }
      }

      // Max Date Validation (if maxDate is provided)
      if (this.maxDate) {
        const maxDateValue = new Date(this.maxDate);
        maxDateValue.setHours(0, 0, 0, 0);
        if (selectedDate > maxDateValue) {
          this.scheduleStartDate = this.maxDate;
        }
      }
      if (this.scheduleStartDate > (this.scheduleEndDate !== '' && this.scheduleEndDate)) {
        this.scheduleStartDate = this.scheduleEndDate;
      }

      this.$emit('schedule-start-date-changed', this.scheduleStartDate);
    },
    setCurrentValueEndDate() {
      this.currentValEndDate = this.scheduleEndDate;
    },
    validateEndDate() {
      if (this.currentValEndDate === this.scheduleEndDate) {
        return;
      }

      if (!this.scheduleEndDate) {
        this.scheduleEndDate = '';
        return; // Stop further validation if empty
      }

      const selectedDate = new Date(this.scheduleEndDate);

      // check if the date is a valid date format
      if (Number.isNaN(selectedDate)) {
        this.scheduleEndDate = this.currentDate;
      }

      // Min Date Validation (if currentDate is provided)
      if (this.currentDate) {
        const minDate = new Date(this.currentDate);
        minDate.setHours(0, 0, 0, 0);
        if (selectedDate < minDate) {
          this.scheduleEndDate = this.currentDate;
        }
      }

      if (this.scheduleStartDate > this.scheduleEndDate) {
        this.scheduleEndDate = this.scheduleStartDate;
      }

      // Max Date Validation (if maxDate is provided)
      // if (this.maxDate) {
      //   const maxDateValue = new Date(this.maxDate);
      //   maxDateValue.setHours(0, 0, 0, 0);
      //   if (selectedDate > maxDateValue) {
      //     this.scheduleEndDate = this.maxDate;
      //   }
      // }
      this.$emit('schedule-end-date-changed', this.scheduleEndDate);
    },
    handleEndDateChange(event) {
      let schedEndDt = event.target.value;
      if (!schedEndDt) {
        this.$emit('schedule-end-date-changed', '');
        return; // Stop further validation if empty
      }

      const selectedDate = new Date(schedEndDt);
      const now = new Date();
      now.setHours(0, 0, 0, 0); // compare only the date part

      // check if the date is a valid date format
      if (Number.isNaN(selectedDate)) {
        return;
      }

      // Min Date Validation (if currentDate is provided)
      if (this.currentDate) {
        const minDate = new Date(this.currentDate);
        minDate.setHours(0, 0, 0, 0);
        if (selectedDate < minDate) {
          return;
        }
      }

      if (this.scheduleStartDate > schedEndDt) {
        schedEndDt = this.scheduleStartDate;
        this.scheduleEndDate = this.scheduleStartDate;
      }

      // Max Date Validation (if maxDate is provided)
      // if (this.maxDate) {
      //   const maxDateValue = new Date(this.maxDate);
      //   maxDateValue.setHours(0, 0, 0, 0);
      //   if (selectedDate > maxDateValue) {
      //     return;
      //   }
      // }
      this.$emit('schedule-end-date-changed', schedEndDt);
    },
  },
};
</script>

<style scoped>
.grid-item {
  padding: 10px 5px 10px 5px;
  max-width: 210px;
}
.delivery {
  max-width: 500px !important;
}
.section {
  display: block;
  margin-bottom: 8px;
}
.filter {
  ::v-deep .dropdown .dropdown-menu {
    li svg {
      font-size: inherit !important;
    }
    .intip4 {
      top: 2px !important;
      margin-left: 5px !important;
      &::after {
        bottom: 200% !important;
        width: 198px !important;
        margin-left: -73px !important;
        font-weight: 400 !important;
        text-align: left !important;
        letter-spacing: 0em !important;
      }
    }
  }
  .filter-ttl {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .filter-group > *:not(:first-child) {
    margin-top: 10px;
  }

  .filter-group {
    margin-bottom: 15px;
    .conv-content {
      position: relative;
      margin-top: 15px;

      .conv-ttl {
        position: absolute;
        top: -80%;
        font-size: 14px;
        color: #707e8a;
      }
    }

    .opt-b1 {
      display: inline-block;
      margin-right: 6px;
    }
    .opt-b2 {
      display: inline-block;
      width: 10rem;
      margin-left: 70px;
      vertical-align: bottom;
      position: absolute;
      margin-top: 5px;
      & .checkbox-btn {
        height: unset;
      }
      ::v-deep .checkbox-btn {
        span {
          position: fixed;
          margin: 0 30px !important;
          font-size: 12px !important;
        }
      }
    }

    .input-conv {
      width: 60px;
    }

    .flp-input {
      padding: 10px;
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.filter {
  &.checkbox-select {
    position: relative;
    color: var(--primarydark2);
    max-width: 549px;
    width: 100%;
    max-height: 540px;
    overflow-y: auto;
  }

  .checkbox-select__trigger {
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
    height: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-select__check-wrapp {
    margin-bottom: 0px;
    position: relative;

    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;

        &:after {
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }

        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }

        &:hover {
          padding-left: 32px;
        }
      }

      &:disabled {
        color: var(--primarydark);
        opacity: 0.6;
        cursor: auto;
      }

      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}
.checkbox-select__check-wrapp {
  margin-bottom: 10px;
}

.checkbox-select__check-wrapp.rounded-input input[type='checkbox'] + label:after {
  border-radius: 10px !important;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group input[type='checkbox'] {
  margin-right: 10px;
}
.form-group label {
  margin-bottom: 5px;
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically */
  width: 255px;
}

.tooltip-icon {
  margin-left: 5px;
  color: #5a5a5a; /* Neutral gray for icon */
  cursor: pointer;
}

.custom-tooltip {
  position: absolute;
  top: -121px; /* Position above the input */
  left: 78%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 12px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;

  max-width: 300px; /* Limit width */
  word-wrap: break-word; /* Break long words */
  text-align: center;
  z-index: 10;
  white-space: normal; /* Allow text wrapping */
  line-height: 1.4; /* Improve readability */
}

.custom-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}

.custom-sched-tooltip {
  position: absolute;
  top: 20px; /* Position above the input */
  left: 65%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 4px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;

  max-width: 300px; /* Limit width */
  word-wrap: break-word; /* Break long words */
  text-align: center;
  z-index: 10;
  white-space: normal; /* Allow text wrapping */
  line-height: 1.4; /* Improve readability */
}
.custom-sched-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}

.flp-btn {
  position: relative;
  position: relative;
  display: inline-block;
  padding: 15px 26px;
  font-size: 12px;
  font-weight: 400;
  color: var(--buttontext);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primarycolor) !important;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-radius: 3px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
}
.flp-btn:hover {
  color: #fff !important;
  background-color: #424242 !important;
}
.flp-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
  color: #666;
  border-color: #999;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
  color: #666;
  border-color: #999;
}
.btn-size2 {
  min-width: 20px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 7px 0px 10px;
  padding: 7px 12px 8px;
  border-radius: 6px;
  &:hover {
    color: #cad1d6 !important;
    background-color: #424242 !important;
    border: none !important;
  }
}
.flp-btn-icon-size2 {
  position: relative;
  top: 0;
  padding: 3px 4px 7px 17px;
  margin: 0 0 0 25px;
  font-size: 18px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.report-name-input {
  width: 100%;
  padding: 10px 18px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide overflow */
  transition: height 0.2s ease; /* Smooth height transition */
}

.remaining-characters {
  margin-top: 5px; /* Space between input and character count */
  font-size: 12px; /* Smaller font for character count */
  color: #5a5a5a; /* Neutral gray for text */
}

.tooltip-container {
  display: flex;
  align-items: center; /* Align tooltip icon and text */
  margin-right: 5px; /* Space between textarea and tooltip */
}

.schedule-pad {
  padding: 0px 8px 8px 30px;
}

.schedule-when {
  padding-bottom: 10px;
}

::v-deep .schedule-when .dropdown.noselect.frequency-select {
  min-width: 250px !important;
}

.email-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: text;
  min-height: 40px;
  width: 250px;
}

.email-chip {
  align-items: center;
  background-color: var(--primarycolor);
  padding: 5px 12px;
  border-radius: 20px;
  margin: 3px;
  font-size: 14px;
  color: var(--buttontext);
  word-wrap: break-word;
  max-width: calc(100% - 20px); /* Reserve space for remove button */
  white-space: normal;
  overflow: hidden;
  flex-shrink: 0;
  position: relative; /* Add this to position the remove button */
  text-overflow: ellipsis; /* Display ellipsis when text overflows */
}

.remove {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
  position: absolute; /* Absolute position */
  right: 3px; /* Position it to the right */
  top: 50%;
  transform: translateY(-50%); /* Center it vertically */
}

.email-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 100px;
  resize: none;
  overflow: hidden;
  padding-top: 6px;
}

.date-picker-wrapper {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.date-input {
  font-family: inherit;
  font-size: 14px;
  color: #000;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--primarycolor); /* Underline style */
  padding: 4px 10px 4px 0; /* Adjust padding for icon space */
  cursor: pointer;
  outline: none;
  width: 120px;
}

.date-input::-webkit-calendar-picker-indicator {
  opacity: 0; /* Hide default date picker icon */
}

.calendar-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primarycolor); /* Match UI theme */
  cursor: pointer;
  font-size: 16px;
  pointer-events: auto; /* Prevent interference */
}

.date-input:focus {
  border-bottom: 2px solid var(--primarycolor); /* Highlighted color */
}

.date-container {
  display: flex;
  gap: 10px; /* Space between fields */
  align-items: center;
  padding-bottom: 10px;
}

.date-field {
  display: flex;
  flex-direction: column;
}

.date-input.small {
  width: 120px; /* Reduce width */
}

.input-icon-wrapper {
  position: relative;
  width: fit-content;
}

.error-highlight {
  border: 2px solid var(--primaryred);
  border-radius: 5px;
  transition: border-color 0.3s ease;
  padding: 2px 2px;
}

.checkbox-line {
  display: flex;
  align-items: center;
}
</style>
