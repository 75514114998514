<template>
  <div class="my-reports-container">
    <MySavedReports
      :rows="rows"
      :scheduled-reports="scheduledReports"
      :loading="isDataLoading"
      :error="errorMessage"
      @update-data-loading="updateDataLoading"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import moment from 'moment';
import appConsoleDataLayer from '@/api/app-console-data';
import MySavedReports from '@/pages/report-builder-v2/my-saved-reports.vue';
import EventBus from '@/adready-vue/helpers/global/event-bus';

export default {
  name: 'MyReports',
  components: {
    MySavedReports,
  },
  data() {
    return {
      rows: [],
      scheduledReports: [],
      isDataLoading: false,
      errorMessage: '',
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
  },
  async mounted() {
    try {
      if (EventBus.fetchSavedReports) {
        // fetch once, and then fetch only again if user save a report
        await this.fetchUserSavedReports();
        EventBus.fetchSavedReports = false;
      } else {
        const data = this.$store.copy('dashboard/templates@data');
        this.processReportsData(data);
      }
    } catch (error) {
      console.error('Error during mounted lifecycle:', error);
      this.errorMessage = 'Failed to load saved reports on initial load.';
    }
  },
  methods: {
    updateDataLoading(isLoading) {
      this.$emit('update-data-loading', isLoading);
    },
    async fetchUserSavedReports() {
      const advertiserId = this.advertiser?.id;
      if (!advertiserId) {
        this.errorMessage = 'Advertiser ID is not available.';
        return;
      }

      this.isDataLoading = true;
      this.errorMessage = '';
      try {
        const data = await appConsoleDataLayer.getUserSavedReports(advertiserId);
        this.$store.set('dashboard/templates@data', data);
        this.processReportsData(data);
      } catch (error) {
        console.error('Error fetching templates:', error);
        this.errorMessage = 'Failed to load saved reports. Please try again later.';
        this.$store.set('dashboard/templates@data', []);
      } finally {
        this.isDataLoading = false;
      }
    },
    processReportsData(data) {
      if (!data || data.length === 0 || data.length === undefined) return [];

      this.rows = data
        ?.filter((item) => item.schedule === null)
        .map((item) => ({
          ...item,
          createdAt: item.createdAt ? moment(item.createdAt).format('MM-DD-YYYY HH:mm:ss') : '',
          dateRange: item.filters ? this.getDataRangeValues(item.filters) : '',
        }))
        .sort((a, b) => b.id - a.id); // Sort by id in descending order

      this.scheduledReports = data
        ?.filter((item) => item.schedule !== null)
        .map((item) => ({
          ...item,
          createdAt: item.createdAt ? moment(item.createdAt).format('MM-DD-YYYY HH:mm:ss') : '',
          dateRange: item.filters ? this.getDataRangeValues(item.filters) : '',
        }))
        .sort((a, b) => b.id - a.id); // Sort by id in descending order

      return {
        rows: this.rows,
        scheduledReports: this.scheduledReports,
      };
    },
    getDataRangeValues(filters) {
      if (filters.dateRangeType === 'CUSTOM') {
        return `${moment(filters.startDate).format('MM-DD-YYYY')} - ${moment(
          filters.endDate
        ).format('MM-DD-YYYY')}`;
      }

      const dateRangeMap = {
        LAST_7_DAYS: 'Last 7 Days',
        LAST_2_WEEKS: 'Last 2 Weeks',
        LAST_30_DAYS: 'Last 30 Days',
        CAMPAIGN_TO_DATE: 'Campaign To Date',
      };

      return dateRangeMap[filters.dateRangeType] || filters.dateRangeType;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-reports-container {
  margin-top: 20px;
}
</style>
