<template>
  <div id="header-section" class="header-section">
    <div
      id="top-filter-bar-container"
      class="bdc_top_band"
      :class="{
        hideTopNav: isCampaignTableExpanded,
        showTopNav: !isCampaignTableExpanded,
        'custom-bg-color': isMniAccount,
      }"
    >
      <div id="top-filter-bar" class="tfb-content-wrap ">
        <div class="tfb-content">
          <div>
            <div
              class="mdn-page-title-txt move-title"
              :class="{ 'move-title': !isLeftNavCollapsed, 'custom-bg-title': isMniAccount }"
            >
              {{ title }}
            </div>
          </div>

          <div class="group-nav">
            <div
              v-if="showTopNavigation && title === 'Media Now Interactive // Motto Reporting'"
              class="ss-nav"
            >
              <div
                class="nav-item hicon"
                :class="selectedSubMenuOption === 'pixelPage' ? 'active' : ''"
                @click="changeSelectedSubMenuOption('pixelPage')"
              >
                <font-awesome-icon :icon="['fal', 'code']" class="inline-fa" />
                <div class="ss-icon-txt">Pixel</div>
              </div>
              <div
                class="nav-item hicon"
                :class="selectedSubMenuOption === 'dashboardPage' ? 'active' : ''"
                @click="changeSelectedSubMenuOption('dashboardPage')"
              >
                <font-awesome-icon :icon="['fal', 'chart-line-up']" class="inline-fa" />
                <div class="ss-icon-txt">Reporting</div>
              </div>
            </div>

            <div class="text-right">
              <div class="tp_acct_wrap" :class="isLeftNavCollapsed ? '' : 'move-acct-wrap'">
                <div id="container-account-user" class="account-with-icon">
                  <switch-account
                    :show-full-view="showFullView"
                    :show-accounts="showAccounts"
                    :class="isLeftNavCollapsed ? '' : 'flexi-dt-cont'"
                    @toggle-account-switcher="toggleAccountSwitcher"
                  />
                  <span class="mp-nav-acct-name" @click="showAccounts = !showAccounts">
                    <font-awesome-icon
                      :icon="['far', 'user']"
                      class="navpencil"
                      :style="isMniAccount ? { color: 'white' } : ''"
                      size="3x"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import _ from 'underscore';

export default {
  components: {
    SwitchAccount: () =>
      import(/* webpackChunkName: "b-dropdown-select-account" */ './switch-account.vue'),
  },
  props: {
    isLeftNavCollapsed: {
      type: Boolean,
      required: true,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: true,
    },
    hideFilterBar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      required: false,
      default: () => '',
      type: String,
    },
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      showFullView: true,
      showAccounts: false,
      showTopNavigation: false,
      selectedSubMenuOption: 'dashboardPage',
    };
  },
  computed: {
    isMniAccount: get('common/isMniAccount'),
  },
  watch: {
    title(n, o) {
      if (!_.isEqual(n, o)) {
        if (this.isMniAccount) {
          this.$store.set('dashboard/selectedDashboardTab', 'overview');
          this.$emit('show-pixel-page', false);
          this.selectedSubMenuOption = 'dashboardPage';
        }
      }
    },
    hideFilterBar(n, o) {
      if (!_.isEqual(n, o)) {
        if (this.isMniAccount && this.selectedSubMenuOption === 'pixelPage') {
          this.$emit('show-pixel-page', false);
          this.selectedSubMenuOption = 'dashboardPage';
        }
      }
    },
  },
  async mounted() {
    if (this.isMniAccount) {
      this.showTopNavigation = true;
      this.changeSelectedSubMenuOption('dashboardPage');
    } else {
      this.showTopNavigation = false;
    }
  },
  methods: {
    toggleAccountSwitcher(val) {
      this.showAccounts = val;
    },
    changeSelectedSubMenuOption(page) {
      this.selectedSubMenuOption = page;
      if (page === 'pixelPage') {
        this.$emit('show-pixel-page', true);
      } else {
        this.$emit('show-pixel-page', false);
        // set back date
        this.setDate();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800';

.scroll1 {
  box-shadow: 0px 3px 20px rgba(0, 20, 70, 0.25) !important;
}

.datepicker.move-date-picker {
  // left: 405px !important;
}
.datepicker.scroll-date-picker {
  position: fixed !important;
  top: 50px !important;
}
.datepicker.move-ext-date-picker {
  //left: 488px !important;
  // left: 466px !important;
  top: 48px !important;
}
.scroll2 {
  top: 0px !important;
}
.topbarscroll {
  position: relative;
  top: -70px !important;
}
div.datepicker {
  position: relative;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 12px;
  width: 768px !important;
  height: 218px !important;
  position: absolute;
  font-family: 'Manrope', sans-serif !important;
  cursor: default;
  top: 117px !important;
  //left: 374px !important;
  //left: 377px !important;
  // left: 448px !important;
  display: none;
  z-index: 999;
  margin: 10px 0px 0px 219px !important;
  border: 1px solid #c0c2ca !important;
  box-shadow: 1px 3px 6px 1px rgb(76 86 107 / 39%) !important;
  // background-color: #f6f7fa;
  // background-size: 6px 6px;
  // background-image: radial-gradient(circle, rgba(0, 0, 0, 0.04) 1px, rgba(0, 0, 0, 0) 1px);
}
div.datepicker th {
  font-weight: 500 !important;
}
.datepicker {
  background-color: #f6f7fa;
}
.compare .btn,
div.datepicker th a.datepickerMonth {
  font-family: 'Manrope', sans-serif !important;
}
.selectwrap {
  width: 203px !important;
  &::after {
    -webkit-text-stroke: 0.7px #fff !important;
  }
}
.datepickerGoPrev span:before {
  content: '\f0d9' !important;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
}
.datepickerGoNext span:before {
  content: '\f0da' !important;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
}
.drp-wrap .compare-label > span {
  font-size: 1.1em !important;
}
[data-id^='date1s'],
[data-id^='date2s'] {
  width: 89% !important;
}
[data-id^='date1e'],
[data-id^='date2e'] {
  float: left;
  width: 89% !important;
  margin-left: -22px;
}
.compare .control-label {
  font-weight: 200 !important;
}
select,
.compare input[type='text'] {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
}

.datepicker {
  border: 0px solid #cccccc;
  border-radius: 4px;
  padding: 8px 30px 10px;
}
.datepickerDays a {
  width: 20px;
  line-height: 16px;
  height: 16px;
  padding-right: 2px;
  font-weight: 300 !important;
}
div.datepicker a {
  color: #787878;
  text-decoration: none;
  cursor: default;
  outline: none;
  font-weight: 300 !important;
}
.hideTopNav {
  display: none;
}
.showTopNav {
  display: block;
}
.zeroTop {
  top: 0 !important;
}
</style>
<style lang="scss" scoped>
.applied-cnt {
  position: absolute;
  background: var(--primaryalt);
  z-index: 2;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  padding: 1px 4px 1px 4px;
  line-height: 1.3em;
  border-radius: 20px;
  text-align: center;
  margin: -6px 0px 0px -8px;
  top: 0px;
  right: -10px;
}
.ftop-icons {
  position: relative;

  display: inline-block;
  vertical-align: top;
  color: var(--primarydark2);
  cursor: pointer;
  position: relative;
  font-size: 16px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;

  .ttip-wrap {
    position: absolute;
    width: max-content;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
    padding-top: 0px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 103;
    pointer-events: none;
    left: -47px;
    top: 20px;
    .ttip {
      display: inline-block;
      position: relative;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: var(--primarydark2);
      color: #fff;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.1em;
      margin-top: 0px;
    }
    .ttip::before {
      content: '';
      display: inline-block;
      left: 50%;
      top: -5px;
      height: 0px;
      width: 0px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-bottom: solid 6px var(--primarydark2);
      position: absolute;
      margin-left: -3px;
    }
  }
  &:hover .fa-filter {
    color: var(--primarycolor);
  }
  &:hover .ttip-wrap {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 6px, 0px);
  }
}

.top-filter-bar {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-left: 279px;
  background-color: rgb(250, 250, 251);
  z-index: 102;
  min-height: 55px;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease 0s;
  border-top: 3px solid var(--primarycolor);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.transparentBg {
  background-color: transparent !important;
  display: none;
}
.text-right {
  text-align: right;
}
.tfb-content-wrap .mdn-page-title-txt {
  line-height: 1.1em;
  margin-bottom: -8px;
  font-size: 22px;
  margin-top: -7px;
}
@media (max-width: 900px) {
  .tfb-content-wrap {
    padding: 4px 20px !important;
  }
}
.tfb-content-wrap {
  padding-right: 17px;
  padding-left: 320px;
  max-width: 1430px !important;
  margin: auto;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  // padding-top: 4px;
  // padding-bottom: 4px;
  .tfb-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 63px;
    .tfb_left {
      //width: calc(100% - 240px);
      //margin-left: 1.9%;
      margin-left: 0;
      .mdn-page-title-txt {
        line-height: 1.1em;
        margin-bottom: -8px;
        font-size: 22px;
        margin-top: -7px;
      }
      .move-title {
        margin-left: 20.1%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    .tfb_right {
      width: 240px;
      text-align: right;
      padding-right: 100px;
      .tp_acct_wrap {
        display: inline-block;
        margin-left: 34%;
        vertical-align: top;
        margin-top: -5px;
        min-width: 160px;
        text-align: right;
        position: relative;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &.move-acct-wrap {
          //margin-left: 64px;
          margin-left: 44%;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.sclosed .tfb-content-wrap {
  padding: 0 31px 0 37px;
}

@media (max-width: 900px) {
  .filter-and-date-tabs {
    padding: 4px 20px !important;
  }
}
.filter-and-date-tabs {
  padding: 0px 35px 0px 325px;
  transition: all 0.3s ease;
  max-width: 1430px;
  margin: auto;
  position: relative;
}
.sclosed .filter-and-date-tabs {
  transition: all 0.3s ease;
  padding-left: 97px;
  margin: auto;
  max-width: 1340px;
  padding-right: 43px;
}
.sub_filters_bar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sub_filters_bar .move-filter-tab {
  margin-left: auto !important;
}
@media (max-width: 900px) {
  .b-datepicker-container .wrap {
    margin-right: 0px !important;
  }
}
.b-datepicker-container {
  left: auto !important;
}
.b-datepicker-container .wrap {
  padding-right: 0 !important;
  min-width: 235px;
  margin-right: -8px;
}
@media (max-width: 900px) {
  .sub_filters_right {
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-end !important;
  }
  .sub_filters_left {
    display: none !important;
  }
}

.sub_filters_right.move-rightside-tab {
  margin-left: 0 !important;
}
.bdc_top_band {
  padding: 0 !important;
  margin-top: -20px;
}
.exheader.sopened .extHeader .tfb-content div:first-child {
  margin-left: -9px;
}
.extHeader .filter-and-date-tabs {
  max-width: 1600px !important;
}
.sopened .extHeader .filter-and-date-tabs {
  padding: 0px 64px 0px 317px;
}
// .exheader.sopened .extHeader .tfb-content .text-right {
//   margin-right: -17px;
// }
// .exheader.sopened .extHeader .filter-and-date-tabs .sub_filters_left {
//   margin-left: -17px;
// }
.exheader.sopened .extHeader .filter-and-date-tabs .sub_filters_right {
  margin-right: -19px;
}
// .exheader .filter-and-date-tabs .sub_filters_left {
//   margin-left: -62px;
// }
// .exheader .filter-and-date-tabs .sub_filters_right {
//   margin-right: -64px;
// }
.exheader .tfb-content div:first-child {
  margin-left: -31px;
}
.exheader .tfb-content .text-right {
  margin-right: -61px;
}

.sclosed .tfb-content-wrap {
  padding-left: 89px !important;
  margin: auto;
  max-width: 1320px !important;
}

.sub_filters_right {
  display: flex;
  text-align: right;
  align-items: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  margin-top: -2px;

  &.move-rightside-tab {
    //margin-left: 383px;
    margin-left: 36.5%;
  }
}
.sub_filters_left {
  display: flex;
  align-items: center;
  margin-top: -2px;
}
::v-deep .sub_filters_container {
  .dropdown {
    margin: 0px 15px 0px 3px;
    border: 1px solid #c5d2d8;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    &:hover {
      border: 1px solid var(--primarycolor);
    }
    .droparw {
      color: #536176;
      // height: 27px;
      // line-height: 37px;
      float: right;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .select {
      height: 37px;
      padding: 3px 20px 3px 10px;
      min-height: 37px !important;
      display: flex;
      align-items: center;
    }
    .select.open {
      background-color: #fff;
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 5px 3px 1px rgba(111, 94, 94, 0.1);
      border-radius: 0 1px 4px 4px;
      border-right: 1px solid #c6d2d8;
      border-left: 1px solid #c6d2d8;
      border-bottom: 1px solid #c6d2d8;
      .norm-option-item {
        color: #536176;
      }
    }
    .selected-opt-wrapper {
      flex-direction: column;
      .selected-opt-header {
        background-color: transparent;
        filter: drop-shadow(0px 0px 5px #fff);
        top: -2px;
        font-size: 0.78em;
        padding: 0px 4px;
        margin-left: -4px;
        color: #969caf;
      }
      .selected-opt {
        color: var(--primarydark2);
        font-size: 14px;
        position: relative;
        text-transform: capitalize;
        bottom: 5px;
        margin-left: 0;
      }
    }
  }
}
.sub_filters_bar {
  position: fixed;
  z-index: 101;
  width: 100%;
  top: 67px;
  display: inline-flex;
  min-height: 56.5px;
  background-color: #f4f5fa;
  box-shadow: none;
  padding-left: 9.5%;
  transition: all 0.3s ease;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  padding-right: 5.5%;
  background-size: 6px 6px;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.04) 1px, rgba(0, 0, 0, 0) 1px);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fafbfd;
  .filter-and-date-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: all 0.3s ease;
  }
  .move-filter-tab {
    //margin-left: 162px;
    margin-left: 15.7%;
  }
}
// .sclosed .filter-and-date-tabs {
//   padding: 4px 35px 4px 30px;
// }
.bdc_top_band {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-left: 279px;
  background-color: rgb(250, 250, 251);
  z-index: 102;
  min-height: 55px;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  border-top: 3px solid var(--primarycolor);
}
table {
  font-weight: 300;
}
::v-deep .wrap:hover {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .drp-wrap .compare-label {
  font-family: 'Manrope', sans-serif !important;
}
::v-deep .drp-wrap {
  position: relative;
  display: block;
  width: 222px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: auto;
  color: #fff;
  border-bottom: 0px;
}
::v-deep .b-datepicker-container {
  bottom: 0 !important;
}
::v-deep .b-datepicker-container .drp-wrap > input {
  color: #595e6b;
  font-size: 1.13em;
  letter-spacing: 0.03em;
  width: 100%;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  box-shadow: none !important;
  padding: 0 !important;
  background: none !important;
  line-height: normal !important;
  height: auto !important;
  max-width: unset;
  margin-bottom: 0;
  min-width: 216px;
}
::v-deep .drp-wrap .compare-label {
  text-align: right;

  font-size: 75%;
  color: rgba(129, 140, 165, 1);
  cursor: pointer;
}

.bdc_top {
  margin-bottom: 30px;
}
.bdc_top_right {
  width: 49%;
  float: right;
  margin-top: -9px;
}
::v-deep .ad-group-select .dropdown-menu-multi-loading {
  min-height: 141px !important;
}
::v-deep .campaign-select .dropdown-menu-multi,
::v-deep .ad-group-select .dropdown-menu-multi,
::v-deep .pixel-select .dropdown-menu-multi {
  margin-top: 6px;
  border-radius: 4px;
  border-top: 1px solid #c6d2d8;
  width: auto !important;
  min-width: 160px !important;
  max-width: 330px !important;
}
::v-deep .campaign-select,
::v-deep .ad-group-select,
::v-deep .pixel-select {
  &.rmvBtmBorderRadius {
    border-radius: 6px !important;
  }
}
.disabled {
  pointer-events: none !important;
  opacity: 0.3;
  filter: brightness(0.9);
}
.inactive-filter-bar {
  .sub_filters_bar {
    pointer-events: none !important;
    opacity: 0.5;
  }
}

// Custom MNI styles here
.custom-bg-color {
  background-color: #696c7e;
  border-top: 3px solid #696c7e;
}
.custom-bg-title {
  color: #ffffff !important;
  font-size: 20px;
  font-family: 'Manrope', sans-serif !important;
}

.opt-b1 {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #c5d2d8;
  height: 40px;
  margin-top: 2px !important;
  min-width: 140px !important;
  margin-left: 4px;
}

.cnv-text {
  font-size: 14px;
  color: #707e8a;
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 5px;
  margin-top: -2px;
}

.dt-input {
  padding-left: 6px;
  font-size: 14px;
  max-width: 30px;
  top: 15px;
  left: 4px;
  background: transparent !important;
  border: transparent !important;
}
.mniAccountStyle {
  min-height: 63px;
  background-image: none !important; /* Override the background-image if isMniAccount is true */
  border-bottom: none;
}
.cstm-mni-filter {
  min-height: 80px;
}
.input-disabled {
  opacity: 0.5;
}
.events-dd {
  padding-top: 2px;
  max-width: 220px !important;
}
.events-dd .placements.dd {
  padding-top: 2px;
  max-width: 225px !important;
}
::v-deep .advance_filters_container {
  &.margin-left {
    margin-left: 12px;
  }
  width: 50%;
  .dropdown {
    border: 1px solid #c5d2d8;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    width: 273px;
    max-width: unset;
    &:hover {
      border: 1px solid var(--primarycolor);
    }
    .droparw {
      color: #536176;
      // height: 27px;
      // line-height: 37px;
      float: right;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .select {
      height: 37px;
      padding: 3px 20px 3px 10px;
      min-height: 37px !important;
      display: flex;
      align-items: center;
      border-radius: 6px 6px 0px 0px;
    }
    .select.open {
      background-color: #fff;
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 5px 3px 1px rgba(111, 94, 94, 0.1);
      border-radius: 0 1px 4px 4px;
      border-right: 1px solid #c6d2d8;
      border-left: 1px solid #c6d2d8;
      border-bottom: 1px solid #c6d2d8;
      .norm-option-item {
        color: #536176;
      }
    }
    .selected-opt-wrapper {
      flex-direction: column;
      .selected-opt-header {
        background-color: transparent;
        filter: drop-shadow(0px 0px 5px #fff);
        top: -2px;
        font-size: 0.78em;
        padding: 0px 4px;
        margin-left: -4px;
        color: #969caf;
      }
      .selected-opt {
        color: var(--primarydark2);
        font-size: 14px;
        position: relative;
        text-transform: capitalize;
        bottom: 5px;
        margin-left: 0;
      }
    }
  }
}
::v-deep .advance_filters_container .top-span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sopened .sub_filters_drop_wrap {
  padding-left: 280px;
}

::v-deep .advance_filters_container .sub-drop .fdrop-label {
  display: inline-block;
  font-size: 0.82em;
  font-weight: 500;
  position: absolute;
  top: -10px;
  background-color: #ffffff;
  padding: 0px 4px;
  margin-left: -4px;
  color: #969caf;
}
.search-checkbox-dropdown {
  max-height: 36px;
  min-width: 180px;
  margin: 2px;
  margin-right: 15px;
}
::v-deep .loading-dropdown .vs__selected-options {
  display: none;
}
::v-deep .loading-dropdown .vs__actions {
  width: 100%;
}

::v-deep .loading-dropdown .vs__spinner {
  margin: 0 auto;
  border-left-color: var(--primarycolor);
}

.ss-nav {
  display: inline-block;
  float: right;
  margin-top: 22px;

  .nav-item {
    display: inline-block;
    color: white;
    cursor: pointer;
  }
}
.group-nav {
  display: flex;
  gap: 10px;
  margin-right: -30px;
}
.nav-item:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);

  .ss-icon-txt {
    margin-top: 26px;
    opacity: 1;
    &.twolines {
      margin-top: 21px;
    }
  }
}
.ss-icon-txt {
  position: absolute;
  left: 50%;
  display: inline-block;
  width: 60px;
  margin-top: 10px;
  margin-left: -30px;
  font-size: 10px;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.hicon {
  position: relative;
  display: inline-block;
  width: 56px;
  padding: 20px 0px;
  margin: 0px;
  font-size: 18px;
  color: #a1a5af !important;
  text-align: center;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  &:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  &.active {
    color: #fff !important;
  }
  &:hover {
    top: -6px;
    max-height: 66px;
    color: #fff;
  }
}
.hicon.active::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--primarycolor);
}
.mp-nav-acct-name {
  font-size: 12px;
  font-weight: 400;
  color: #a1a5af;
  cursor: pointer;
  margin-top: 25px;
  // margin-left: -130px;
  padding-left: 10px;
  .navpencil {
    height: 60px;
    margin-top: -5px;
    font-size: 19px;
    color: #797c8a;
    cursor: pointer;
    width: 0.875em;
  }
}
.account-with-icon {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
}
</style>
