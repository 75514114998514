<template>
  <div
    ref="b-select-checkbox"
    class="dropdown noselect checkbox-select"
    style="margin-right: 20px"
    tabindex="1"
    :class="[{ disabled }, className]"
  >
    <div
      :id="componentId"
      class="select"
      :class="[
        showDropdown ? 'open' : '',
        selectedVal,
        overrideClass,
        capitalizeText ? '' : 'no-capitalize',
      ]"
      @click="showDropdown = !showDropdown"
    >
      <div class="selected-opt-wrapper tooltip">
        <span class="selected-opt-header">{{ header ? `${header}: ` : '' }}</span>
        <font-awesome-icon
          v-if="selectedTextIcon"
          class="selected-icon"
          :icon="[selectedTextIconStyle, selectedTextIcon]"
          size="1x"
        />
        <span class="selected-opt">{{ selectedVal }}</span>
        <span v-if="showToolTip" class="tooltiptext">{{ selectedVal }}</span>
      </div>
      <font-awesome-icon
        :icon="['far', showDropdown ? 'chevron-up' : 'chevron-down']"
        class="droparw"
      />
    </div>
    <div
      v-if="showDropdown"
      id="dropdown"
      class="checkbox-select__dropdown"
      :class="{ activeSearch: showLoader }"
    >
      <div class="checkbox-select__search-wrapp">
        <input
          v-model="search"
          type="text"
          placeholder="search filters..."
          @focus="showLoader = true"
          @blur="showLoader = false"
        />
      </div>

      <ul id="customScroll" class="checkbox-select__filters-wrapp" data-simplebar-auto-hide="false">
        <li
          v-for="(option, i) in filteredList"
          :key="i"
          :class="[option.showStar ? 'rollup-option' : 'norm-option', option.name || option]"
          @click="
            $emit('change', option.id || option, option.name || option);
            showDropdown = false;
          "
        >
          <div class="checkbox-select__check-wrapp">
            <label :for="i">{{ option.name || option }}</label>
          </div>
          <span v-if="option.infoText" :data-tool-tip="option.infoText" class="intip4">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
          <div v-if="option.children && option.children.length">
            <span v-for="(child, ci) in option.children" :key="ci" class="psub">{{ child }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: () => 'Select',
    },
    componentId: {
      type: String,
      required: false,
      default: () => '',
    },
    header: {
      type: String,
      required: false,
      default: () => '',
    },
    selected: {
      type: [String, Number, Array, Object, Boolean],
      required: false,
      default: 'None',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    showToolTip: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedTextIconStyle: {
      type: String,
      required: false,
      default: 'far',
    },
    selectedTextIcon: {
      type: String,
      required: false,
      default: '',
    },
    overrideClass: {
      type: String,
      required: false,
      default: '',
    },
    capitalizeText: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      search: '',
      showLoader: false,
    };
  },
  computed: {
    selectedVal() {
      const select = this.options.find((opt) => opt.id === this.selected) || {};

      if (select.name) {
        return select.name;
      }
      if (this.selected?.displayText) {
        return this.selected?.displayText;
      }

      if (
        this.selected &&
        typeof this.selected === 'string' &&
        this.selected.toUpperCase() === 'NONE' &&
        this.options.length
      ) {
        // this.selected = this.options[0];
        return this.options[0].name || this.options[0];
      }

      return this.selected;
    },
    filteredList() {
      return this.options.filter((option) => {
        return option.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },

  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
        this.search = '';
      }
    },
    closeDropdown() {
      this.showDropdown = false;
      this.search = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.psub {
  display: block;
  padding-left: 10px;
  font-size: 12px;
  color: #a4acbb;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.intip4 {
  position: relative;
  top: 0;
  display: inline-block;
  margin-left: 8px;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.intip4[data-tool-tip]::after {
  width: 155px;
  margin-left: -70px;
  font-size: 12px;
  font-weight: 400;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 1);
}
.intip4[data-tool-tip]:hover::after {
  bottom: -145%;
  transform: scale(1);
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-block;
  width: 80%; /* Ensure the wrapper doesn't stretch beyond the container */
  overflow: hidden;
}
.selected-opt {
  // display: inline-block;
  width: 90%;
  overflow: wrap;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
svg {
  float: right;
}
ul li svg {
  position: relative;
  font-size: 18px;
  color: #cbccd2;
  border: none;
  fill: #cbccd2;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    min-width: 200px;
    height: 100%;
    font-size: 14px;
    color: var(--primarydark2);
    text-align: left;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-radius: 4px;
    transition: all 0.3s ease;
    -webkit-touch-callout: none;
  }
  .light-theme {
    .selected-opt-wrapper {
      svg {
        color: #555 !important;
        &.check-off {
          color: transparent !important;
        }
      }
    }
    .left-active-tab-cont {
      .dropdown .select {
        background-color: #fff !important;
        border: 1px solid #edeefb !important;
        border-bottom: 0px !important;
        border-radius: 5px 5px 0px 0px !important;
        padding-bottom: 3px !important;
      }
    }
    .right-active-tab-cont {
      .dropdown .select {
        background-color: #fff !important;
        border: 1px solid #edeefb !important;
        border-bottom: 0px !important;
        border-radius: 5px 5px 0px 0px !important;
        padding-bottom: 3px !important;
      }
    }
    .selected-opt {
      color: #212325;
    }
    .dropdown.b-select-dd {
      .select {
        background-color: transparent;
        border: 1px solid rgba(170, 170, 170, 0.1);
        &.open {
          background-color: #383a44;
        }
      }
      .selected-opt-header {
        color: #707e8a;
      }
      .selected-opt {
        color: #cad1d6;
      }
      .droparw {
        color: #fff;
      }
      .dropdown-menu {
        background-color: rgba(45, 46, 53, 0.98);
        box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
        li {
          color: #cad1d6;
          &:hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }
        .norm-option .icon {
          color: #cad1d6;
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        background-color: #f9f9f9;
        li {
          color: #555;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
        .norm-option .icon {
          color: #555;
        }
      }
      .select {
        background-color: #ffffff;
        border: 1px solid #c5d2d8;
        color: #555;
        &.open {
          background-color: #ffffff;
        }
      }
    }
  }
  .dropdown .select {
    display: block;
    min-height: 40px;
    padding: 8px 10px;
    text-transform: capitalize;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(170, 170, 170, 0.1);
    border-radius: 4px;
    &.open {
      background-color: #383a44;
    }
  }
  .dropdown .select.no-capitalize {
    text-transform: none !important; /* Remove the capitalization when the class is applied */
  }
  .greyBackground {
    background-color: #30323a;
  }
  .droparw {
    position: relative;
    top: 5px;
    float: right;
    font-size: 11px;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  }
  .dropdown .dropdown-menu li {
    padding: 10px 17px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      font-size: 17px;
    }
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active {
    background-color: #333;
  }
  .roll-preset {
    display: inline-block;
    padding: 0px 4px;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
    color: #cad1d6;
    text-transform: uppercase;
    background-color: var(--primarycolor);
    border-radius: 12px;
    svg {
      top: 0;
      width: 0.625rem !important;
    }
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
.tooltip {
  // position: relative;
}
.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 200px;
  min-width: 100px;
  width: auto;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
  text-align: center;
  border-radius: 3px;
  transition: ease 0.3s;
  line-height: 1.2em;
  transform-origin: bottom center;
  padding: 5px;
  position: absolute;
  z-index: 10;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

textarea,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
}

.spinner-border {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 5px;
  height: 38px;
  width: 550px !important;
}

.spinner-border-half {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 5px;
  height: 38px;
  width: 273px !important;
}

.spinner-style-half {
  margin-top: -20px !important;
}

.spinner-style {
  margin-top: -20px !important;
}

.checkbox-select {
  position: relative;
  max-width: 549px;
  width: 100%;
  color: var(--primarydark2);
  @media only screen and (max-width: 600px) {
    margin: 100px auto 0;
  }
  &__trigger {
    border-radius: 10px;
    background: #fff;
    // background: #fff
    //   url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    position: relative;
    z-index: 1;
    // box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.13);
    // height: 86px;
    height: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
      height: 70px;
    }
    &.isActive {
      border-radius: 10px 10px 0 0;
      // background: #f2f2f2
      // url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
      svg {
        transform: rotate(-180deg) scale(0.5);
      }
    }
    &.isDisabled {
      color: var(--primarydark);
      opacity: 0.6;
      cursor: auto;
    }
    // &:hover {
    //   background: #f4f4f4
    //     url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    // }
    svg {
      width: 20px;
      opacity: 0.6;
      stroke: 4px;
      transform: scale(0.5);
      transition: all 0.4s ease;
      @media only screen and (max-width: 600px) {
        width: 22px;
      }
    }
  }
  &__title {
    font-size: 12px;
    color: var(--primarydark2);
    flex: 1;
    padding-right: 25px;
    letter-spacing: 1px;
    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
  }
  &__dropdown {
    // min-width: 300px; // enable to make good spacing for button select and clear all buttons.
    // opacity: 0;
    // visibility: hidden;
    background: #fff;
    min-width: 200px;
    width: 255px !important;
    //url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    position: absolute;
    left: 0;
    right: 0;
    // box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 0 0 8px 8px;
    // overflow: hidden;
    z-index: 9;
    padding-bottom: 2px;
    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 0;
      display: block;
      height: 4px;
      background-color: transparent;
    }
    // &:after {
    //   opacity: 0;
    //   background: #000;
    //   left: -200px;
    //   width: 200px;
    //   background-color: #2980b9;
    //   transition: opacity 0.3s ease;
    //   animation: load 1.8s linear infinite;
    //   background: linear-gradient(
    //     135deg,
    //     rgba(143, 36, 237, 1) 20%,
    //     rgba(143, 36, 237, 1) 20%,
    //     rgba(143, 36, 237, 1) 22%,
    //     rgba(143, 36, 237, 1) 25%,
    //     rgba(16, 124, 179, 1) 100%
    //   );
    // }
    // &:before {
    //   width: 100%;
    //   background-color: #000;
    // }
    &.activeSearch {
      &:after {
        opacity: 1;
      }
    }
    .simplebar-scrollbar {
      width: 3px;
      right: 1px;
    }
  }
  &__search-wrapp {
    padding: 10px 10px 10px;
    @media only screen and (max-width: 600px) {
      padding: 10px 15px 5px;
    }
    input {
      width: 100%;
      height: 30px;
      // height: auto;
      border-width: 0 0 2px;
      border-style: solid;
      border-color: #000;
      font-size: 13px;
      // font-family: 'Roboto Slab', serif;
      font-family: 'Manrope', sans-serif;
      background: transparent;
      background-color: #fff;
      border: 1px solid #c5d2d8;
      padding: 1.3em 1em;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #b8b8b8;
      font-size: 13px;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #b8b8b8;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #b8b8b8;
      opacity: 1;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #b8b8b8;
      opacity: 1;
    }
  }
  &__col {
    display: flex;
    font-size: 12px;
    padding: 0 25px;
    justify-content: space-between;
    text-transform: uppercase;
    gap: 15px;
    @media only screen and (max-width: 6000px) {
      padding: 0 15px;
      gap: 15px;
    }
  }
  &__select-all {
    border: 1px solid #d7dfe1;
    border-radius: 5px;
    padding: 1px 5px;
    color: var(--primarydark2);
    background-color: #f6f8fb;
    font-size: 0.95em;
    font-weight: 500;
    letter-spacing: 0.05em;
    cursor: pointer;
    &:hover {
      border: 1px solid #aaaab9;
    }
    // label {
    //   cursor: pointer;
    // }
    input {
      display: none;
    }
  }
  &__select-filtered {
    border: 1px solid #d7dfe1;
    border-radius: 5px;
    padding: 1px 5px;
    color: var(--primarydark2);
    background-color: #f6f8fb;
    font-size: 0.95em;
    font-weight: 500;
    letter-spacing: 0.05em;
    cursor: pointer;
    &:hover {
      border: 1px solid #aaaab9;
    }
    // label {
    //   cursor: pointer;
    // }
    input {
      display: none;
    }
  }
  &__filters-wrapp {
    height: 200px;
    overflow-y: auto;
  }

  &__check-wrapp {
    padding: 8px 25px;
    margin-bottom: 0px;
    border-top: 1px solid #f2f2f9;
    position: relative;
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
    }
    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;
        &:after {
          // border: solid 2px #000;
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }
        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          // background-color: #000;
          // background-color: var(--primarycolor);
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }
        &:hover {
          padding-left: 32px;
        }
      }
      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}

.checkbox-select__check-wrapp {
  padding: 8px 25px;
  margin-bottom: 0px;
  border-top: 1px solid #f2f2f9;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }

  label {
    position: relative;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    line-height: 22px;
    display: inline-block;
    border-radius: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: padding 0.25s ease;

    &:hover {
      padding-left: 3px;
    }
  }
}

.dropdown-text-container {
  flex: 1;
  flex-direction: column;
}
.header-text {
  background-color: transparent;
  filter: drop-shadow(0px 0px 5px #fff);
  top: -2px;
  font-size: 0.78em;
  font-family: 'Manrope', sans-serif;
  line-height: 1.4285em;
  color: #969caf;
}

@keyframes load {
  0% {
    left: -200px;
    width: 20%;
  }
  50% {
    width: 40%;
  }
  70% {
    width: 60%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  100% {
    left: 100%;
  }
}

.link {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  z-index: 9999;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
  }
  .fa {
    font-size: 28px;
    margin-right: 8px;
    color: #fff;
  }
}
</style>
