var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rpt_main_wrap"},[_c('div',{staticClass:"rpt_panel def-box expan"},[_c('div',{staticClass:"tabs-container"},[_c('div',{staticClass:"tabs"},[_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"tab",class:{
            active: _vm.isActiveTab(index),
            disabled:
              _vm.isTabDisabled(index) ||
              _vm.showSpinnerWhileSavingTemplate ||
              _vm.showSpinnerWhileEmailing ||
              _vm.isDataLoading,
          },attrs:{"id":'tab-' + index + '-' + tab.name.replace(/\s+/g, '')},on:{"click":function($event){return _vm.handleTabClick(index)}}},[_vm._v(" "+_vm._s(tab.name)+" "),(_vm.isActiveTab(index))?_c('div',{staticClass:"active-line"}):_vm._e()])}),(_vm.isActiveTab(2))?_c('div',{staticClass:"flp-controls"},[_c('div',{staticClass:"fright",class:{ disabled: _vm.isViewReportLoading }},[(_vm.isVisible)?_c('b-save-template-btn',{attrs:{"external-open":_vm.openSaveTemplatePopup,"is-loading":_vm.showSpinnerWhileSavingTemplate},on:{"save-template":_vm.saveTemplate}}):_vm._e(),_c('k-download-btn',{attrs:{"preparing":_vm.downloadBtn.preparing,"done":_vm.downloadBtn.done},on:{"click":function($event){$event.stopPropagation();}},nativeOn:{"click":function($event){return _vm.download()}}}),_c('b-email-btn',{attrs:{"email-status":_vm.emailSentStatus,"external-open":_vm.openEmailPopup,"show-fill-with-user-email":true,"is-loading":_vm.showSpinnerWhileEmailing,"is-new-report-builder":true},on:{"email-to":_vm.emailReport}})],1)]):_vm._e()],2),_c('div',{staticClass:"tab-content"},[_c(_vm.currentComponent,{ref:"viewReportComponent",tag:"component",attrs:{"active-solution-id":_vm.activeSolutionId,"template-name":_vm.templateName,"touch-reports-email-status":_vm.touchReportEmailStatus},on:{"update-download-status":_vm.updateDownloadStatus,"update-email-sent-status":_vm.updateEmailSentStatus,"update-open-email-popup":_vm.updateOpenEmailPopup,"update-view-report-loading":_vm.updateViewReportLoading,"update-save-template-visibility":_vm.updateSaveTemplateVisibility,"show-spinner-while-saving-template":_vm.updateShowSpinnerWhileSavingTemplate,"show-spinner-while-emailing":_vm.updateShowSpinnerWhileEmailing,"update-template-name":_vm.updateTemplateNameVal,"export-n-send-touch-reports":_vm.triggerEmailForAllTouch,"export-n-send-all-ep-reports":_vm.triggerEmailForAllEp,"update-data-loading":_vm.updateDataLoading}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }